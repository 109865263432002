import * as React from 'react';
import { STORES, IStores } from './root';
import { TReactFCP, TReactFCR } from '../types/utils';

export const StoresContext: React.Context<IStores> = React.createContext<IStores>(STORES);

export function useStores(): IStores {
    return React.useContext(StoresContext);
}

export function StoresProvider(props: TReactFCP<{ value?: IStores }>): TReactFCR {
    const defaultStores: IStores = useStores();
    const providerStores: IStores = props.value || defaultStores;
    return <StoresContext.Provider value={providerStores}>{props.children}</StoresContext.Provider>;
}
