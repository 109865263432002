import * as React from 'react';
import clsx from 'clsx';
import { TReactFCR, TReactFCP } from '../../lib/types/utils';
import { Link } from '../Link';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles/withStyles';

type ClassKey = 'root' | 'disableFocusStates' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    disableFocusStates: {
        '&:focus, &:hover': { color: 'inherit' }
    },
    xxs: { height: '16px', width: '16px' },
    xs: { height: '24px', width: '24px' },
    sm: { height: '32px', width: '32px' },
    md: { height: '40px', width: '40px' },
    lg: { height: '48px', width: '48px' },
    xl: { height: '56px', width: '56px' },
}));

export type TSiteLogoP = {
    className?: string;
    classes?: Partial<ClassNameMap<Exclude<ClassKey, 'disableFocusStates'>>>;
    size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    disableLink?: boolean;
};

export function SiteLogo(props: TReactFCP<TSiteLogoP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const className: string = clsx(
        styles.root,
        props.className,
        props.classes && props.classes.root,
        { [styles.disableFocusStates]: props.disableLink === true }
    );
    const logoClassName: string = clsx(
        {
            [clsx(styles.xxs, props.classes && props.classes.xxs)]: props.size === 'xxs',
            [clsx(styles.xs, props.classes && props.classes.xs)]: props.size === 'xs',
            [clsx(styles.sm, props.classes && props.classes.sm)]: props.size === 'sm',
            [clsx(styles.md, props.classes && props.classes.md)]: props.size === 'md' || props.size === undefined,
            [clsx(styles.lg, props.classes && props.classes.lg)]: props.size === 'lg',
            [clsx(styles.xl, props.classes && props.classes.xl)]: props.size === 'xl',
        }
    );
    const logo: React.ReactNode = <Logo fill='currentColor' className={logoClassName} />;
    if (props.disableLink) {
        return <div className={className}>{logo}</div>;
    }
    return <Link className={className} variant='internal' to='/' disableUnderline disableColor>{logo}</Link>;
}
