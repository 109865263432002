import { Theme, useMediaQuery, Options } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export function useBreakpointWidth(mediaQueryOptions?: Options): Breakpoint {
    const options: Options = mediaQueryOptions ?? {};
    const isSM: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'), options);
    const isMD: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'), options);
    const isLG: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'), options);
    const isXL: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.only('xl'), options);

    if (isXL) return 'xl';
    if (isLG) return 'lg';
    if (isMD) return 'md';
    if (isSM) return 'sm';
    return 'xs';
}
