import { observable, runInAction, computed, action, IObservableValue } from 'mobx';
import { Theme } from '@material-ui/core';
import { TThemeVariant, DARK_THEME, LIGHT_THEME } from '../theme/variants';
import { TWindowDimensions } from '../types/ui';
import * as JssConfig from '../../config/muiStylesProviderProps';
import { Jss, GenerateId } from 'jss';

export class UIStore {
    @observable private _theme!: Theme;
    @observable private _themeVariant!: IObservableValue<TThemeVariant>;
    @observable private _windowDimensions!: TWindowDimensions;

    constructor(theme: TThemeVariant = 'light') {
        runInAction('Initialize State', () => {
            this._themeVariant = observable.box(theme);
            this.changeTheme(theme);

            this._windowDimensions = observable({ width: 0, height: 0 });
            // Avoid window and docuemnt not being defined during a SSR build
            try {
                this._windowDimensions.width = window.innerWidth;
                this._windowDimensions.height = window.innerHeight;
                window.addEventListener('resize', this._handleWindowResize);
            } catch { }
        });
    }

    @action public cleanup(): void {
        window.removeEventListener('resize', this._handleWindowResize);
    }

    public get jss(): Jss {
        return JssConfig.JSS_INSTANCE;
    }

    public get jssClassNameGenerator(): GenerateId {
        return JssConfig.JSS_CLASS_NAME_GENERATOR;
    }

    @computed public get themeVariant(): TThemeVariant {
        return this._themeVariant.get();
    }

    @computed public get theme(): Theme {
        return this._theme;
    }

    @action.bound public changeTheme(variant: TThemeVariant): void {
        this._themeVariant.set(variant);
        switch (variant) {
            case 'dark':
                this._theme = DARK_THEME;
                break;
            case 'light':
            default:
                this._theme = LIGHT_THEME;
                break;
        }
    }

    @computed public get window(): TWindowDimensions {
        return this._windowDimensions;
    }

    @action private _handleWindowResize = (e: UIEvent): void => {
        this._windowDimensions.width = window.innerWidth;
        this._windowDimensions.height = window.innerHeight;
    }
}

export const UI_STORE: UIStore = new UIStore();
