import * as React from 'react';
import { action, IObservableValue, observable } from 'mobx';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { AppBarProps } from '@material-ui/core/AppBar';
import { useLocalStore } from 'mobx-react';
import { TReactFCP, TReactFCR } from '../types/utils';
import { useBreakpointWidth } from '../../hooks/useWidth';

type BreakpointMap<T> = Record<Breakpoint, T>;

// Nav

type TTemporaryDrawerNavLayoutConfig = {
    width: number;
};

type TInlineDrawerNavLayoutConfig = {
    isCollapsible: boolean;
    width: { full: number, collapsed: number; };
};

type TNavLayoutBreakpointConfig = {
    variant: 'inlineDrawer' | 'temporaryDrawer' | 'headerList';
    inlineConfig?: TInlineDrawerNavLayoutConfig;
    temporaryConfig?: TTemporaryDrawerNavLayoutConfig;
};
type TNavLayoutConfig = BreakpointMap<TNavLayoutBreakpointConfig>;

export type TNavLayoutState = {
    isCollapsable: boolean;         // Used for building the UI
    isCollapsed: boolean;           // Used for inlineDrawer
    isOpenable: boolean;            // Used for building the UI
    isOpen: boolean;                // Used for temporaryDrawer
    width: number;                  // Used for inline and temporary drawers
    collapsedWidth: number;         // Used for inline and temporary drawers
    // isInlineWithContent: boolean;   // Used for inline Drawer
};

export const NAV_LAYOUT_CONFIG: TNavLayoutConfig = {
    xs: {
        variant: 'temporaryDrawer',
        temporaryConfig: {
            width: 160,
        }
    },
    sm: {
        variant: 'headerList'
    },
    md: {
        variant: 'headerList'
    },
    lg: {
        variant: 'headerList'
    },
    xl: {
        variant: 'headerList'
    }
};

// Header

type THeaderLayoutBreakpointConfig = {
    position: NonNullable<AppBarProps['position']>;
    isDense: boolean;
};
type THeaderLayoutConfig = BreakpointMap<THeaderLayoutBreakpointConfig>;

export type THeaderLayoutState = & THeaderLayoutBreakpointConfig;

export const HEADER_LAYOUT_CONFIG: THeaderLayoutConfig = {
    xs: { position: 'static', isDense: true },
    sm: { position: 'static', isDense: false },
    md: { position: 'static', isDense: false },
    lg: { position: 'static', isDense: false },
    xl: { position: 'static', isDense: false }
};

// App

type TLayoutConfig = {
    nav: TNavLayoutConfig;
    header: THeaderLayoutConfig;
};

// const APP_LAYOUT_CONFIG: TLayoutConfig = {
//     nav: NAV_LAYOUT_CONFIG,
//     header: HEADER_LAYOUT_CONFIG
// };

// Store

type TLayoutStoreConfig = {
    breakpoint: Breakpoint;
};

export interface ILayoutStore {
    isNavCollapased: IObservableValue<boolean>;
    isNavOpen: IObservableValue<boolean>;
    currentHeaderLayout: THeaderLayoutState;
    currentNavLayout: TNavLayoutState;
    toggleNavCollapse(): void;
    toggleNavOpen(): void;
}

function createStore(config: TLayoutStoreConfig): ILayoutStore {
    const initNavState: TNavLayoutBreakpointConfig = NAV_LAYOUT_CONFIG[config.breakpoint];
    const initIsOpen: boolean = initNavState.variant === 'inlineDrawer';
    const store: ILayoutStore = {
        // Don't access these properties directly.
        isNavCollapased: observable.box(false),
        isNavOpen: observable.box(initIsOpen),

        get currentHeaderLayout(): THeaderLayoutState {
            return HEADER_LAYOUT_CONFIG[config.breakpoint];
        },
        get currentNavLayout(): TNavLayoutState {
            const layout: TNavLayoutBreakpointConfig = NAV_LAYOUT_CONFIG[config.breakpoint];
            const isInline: boolean = layout.variant === 'inlineDrawer';
            const isTemporary: boolean = layout.variant === 'temporaryDrawer';
            const isCollapsable: boolean = isInline && (layout.inlineConfig?.isCollapsible ?? false);
            const isOpenable: boolean = isTemporary;
            const isOpen: boolean = isOpenable ? store.isNavOpen.get() : isInline;
            let width: number = 200;
            let collapsedWidth: number = 64;
            if (isInline && layout.inlineConfig) {
                width = layout.inlineConfig.width.full;
                collapsedWidth = layout.inlineConfig.width.collapsed;
            } else if (isTemporary && layout.temporaryConfig) {
                width = layout.temporaryConfig.width;
            }
            return {
                isOpen,
                isOpenable,
                isCollapsed: store.isNavCollapased.get(),
                isCollapsable,
                width,
                collapsedWidth
            };
        },
        toggleNavCollapse: action(function toggleCollapse() {
            store.isNavCollapased.set(!store.isNavCollapased.get());
        }),
        toggleNavOpen: action(function toggleOpen() {
            store.isNavOpen.set(!store.isNavOpen.get());
        })
    };
    return store;
};

export const INITIAL_APP_LAYOUT_STORE: ILayoutStore = createStore({ breakpoint: 'xs' });

// Context

export const LayoutStoreContext: React.Context<ILayoutStore> = React.createContext<ILayoutStore>(
    INITIAL_APP_LAYOUT_STORE
);

export function LayoutProvider(props: TReactFCP): TReactFCR {
    // TODO: This avoids immediate rerendering on first page load, but breaks the page CSS in prod on first load :/
    //       Without this, it introduces a React warning in dev mode
    // const breakpoint: Breakpoint = useBreakpointWidth({ noSsr: true });
    const breakpoint: Breakpoint = useBreakpointWidth();
    const store: ILayoutStore = useLocalStore<ILayoutStore>(createStore, { breakpoint });
    return <LayoutStoreContext.Provider value={store}>{props.children}</LayoutStoreContext.Provider>;
}

export function useLayoutStore(): ILayoutStore {
    const store: ILayoutStore = React.useContext(LayoutStoreContext);
    if (store === null) {
        throw new Error('useLayoutStore() must be used within a LayoutProvider.');
    }
    return store;
}
