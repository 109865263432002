import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { Helmet } from 'react-helmet';

export type TPerson = {
    name: string;
    image: string;
    brandLogo?: string;
    website: string;
};

export type TWebPage = {
    person?: TPerson;
    description: string;
    canonicalURL: string;
    title: string;
};

export type TBlogPost = {
    title: string;
    defaultTitle: string;
    // spoiler: string;
    image?: string;
    description: string;
    author: TPerson;
    publishDate: string;    // ISO8601
    modifiedDate: string;   // ISO8601
    language: string;
    keywords: string[];
    timeToRead: number;     // Minutes
};

export type TWebSite = {
    logo: string;
    url: string;
    name: string;
    description: string;
};

export type TSchemaOrgP = {
    site: TWebSite;
    page: TWebPage;
    blogPost?: TBlogPost;
};

export function SchemaOrg(props: TReactFCP<TSchemaOrgP>): TReactFCR {
    const { site, page, page: { person }, blogPost }: TSchemaOrgP = props;
    const schema: Array<object> = [];
    if (page.canonicalURL === site.url) {
        schema.push(
            {
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                '@id': site.url,
                name: site.name,
                image: site.logo,
                description: site.description
            }
        );
    }
    else {
        schema.push(
            {
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                '@id': page.canonicalURL,
                url: page.canonicalURL,
                description: page.description,
                name: page.title,
                mainEntityOfPage: { '@type': 'WebSite', '@id': site.url }
            }
        );
    }
    if (person) {
        schema.push(
            {
                '@context': 'http://schema.org',
                '@type': 'Person',
                '@id': person.website,
                sameAs: person.website,
                url: person.website,
                name: person.name,
                image: person.image,
                brand: {
                    logo: person.brandLogo || person.image
                }
            }
        );
    }
    if (blogPost) {
        const post: Record<string, any> = {
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            '@id': page.canonicalURL,
            name: blogPost.title,
            alternateName: blogPost.defaultTitle,
            url: page.canonicalURL,
            description: blogPost.description,
            datePublished: blogPost.publishDate,
            dateModified: blogPost.modifiedDate,
            headline: blogPost.title,
            inLanguage: blogPost.language,
            keywords: blogPost.keywords,
            timeRequired: `PT${blogPost.timeToRead}M`,
            publisher: {
                '@type': 'Person',
                '@id': site.url,
                sameAs: site.url,
                url: site.url,
                name: site.name,
            },
            // position: blogPost.positionInListing,
            // memberOf: {
            //     // Organization (GTM)
            // },
            // owns: {
            //     // OwnershipInfo or Product (Paddock Tech / 1Lap)
            // },
            author: {
                '@type': 'Person',
                '@id': blogPost.author.website,
                sameAs: blogPost.author.website,
                url: blogPost.author.website,
                name: blogPost.author.name,
                image: blogPost.author.image,
            },
            mainEntityOfPage: {
                '@type': 'WebSite',
                '@id': site.url
            }
        };
        if (blogPost.image) {
            post.image = blogPost.image;
        }
        schema.push(post);
    }
    return (
        <Helmet defer={false}>
            <script key='schema.org' type='application/ld+json'>{JSON.stringify(schema, undefined, 4)}</script>
        </Helmet>
    );
}
