import * as React from 'react';
import { TReactFCR, TReactFCP } from '../../lib/types/utils';
import { Providers } from './Providers';
import { Root } from './layout/Root';
import { Content } from './layout/Content';
import { Header } from './layout/Header';
import { Hidden } from '@material-ui/core';
import { Nav } from './layout/Nav';
import { Footer } from './layout/Footer';
import { NAV_LINKS } from '../../config/nav';

export function App(props: TReactFCP): TReactFCR {
    return (
        <Providers>
            <Root>
                <Header />
                <Hidden smUp><Nav variant='drawer' config={NAV_LINKS} /></Hidden>
                <Content>{props.children}</Content>
                <Footer />
            </Root>
        </Providers>
    );
}
