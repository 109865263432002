import * as React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { SEO } from './SEO';
import { TReactFCR, TReactFCP } from '../../lib/types/utils';
import '../../assets/fonts/fonts.css'; // Please read FONTS.md before changing this line.

type ClassKey = '@global';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => {
    return {
        '@global': {
            html: {
                boxSizing: 'border-box',
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'greyscale',
            },
            '*, *::before, *::after': {
                boxSizing: 'border-box'
            },
            '::selection': {
                backgroundColor: theme.palette.custom.text.selection.color,
                color: theme.palette.custom.text.selection.contrastText.main
            },
            body: {
                ...theme.typography.sizing(4),
                fontFamily: theme.typography.fontFamily,
                fontWeight: 400,
                fontKerning: 'normal',
                fontFeatureSettings: theme.typography.fontFeatureSettings.body,
                wordWrap: 'break-word',
                margin: 0,
                backgroundColor: theme.palette.custom.background.default.color,
                color: theme.palette.custom.text.color.main
            },
            [`
                h1, h2, h3, h4, h5, h6, ul, ol, dl, dd, p, pre, figure, hgroup, table, fieldset, form, noscript, iframe,
                img, hr, address, blockquote, .grvsc-container-wrapper
            `]: {
                margin: 0, padding: 0, marginBottom: theme.baseline.blockMarginBottom
            },
            'img': { maxWidth: '100%' },
            'blockquote': {
                ...theme.typography.blockquote,
                fontWeight: theme.typography.fontWeightRegular,
                paddingLeft: theme.spacing(6),
                marginRight: theme.spacing(4),
                fontStyle: 'italic',
                color: theme.palette.custom.quote.text.color,
                borderLeft: `${theme.spacing(1)}px solid ${theme.palette.custom.quote.border.color}`
            },
            'blockquote *:last-child, li *:last-child, p *:last-child': { marginBottom: 0 },
            'hr': {
                border: 'none',
                background: theme.palette.custom.divider.main,
                height: '2px',
                marginBottom: `calc(${theme.baseline.blockMarginBottom}px - 2px)` // Adjust margin for height of <hr>
            },
            'strong, b, dt, th': {
                fontWeight: theme.typography.fontWeightBold
            },
            'body::backdrop': {
                backgroundColor: theme.palette.background.default
            },
            'pre, code': {
                fontFamily: theme.typography.fontFamilyMono,
            },
            'pre code': {
                backgroundColor: 'inherit',
                color: 'inherit',
                padding: 0,
            },
            'pre': {
                lineHeight: 'initial',
                padding: theme.baseline.prePadding,
                whiteSpace: 'pre',
                wordSpacing: 'normal',
                wordBreak: 'normal',
                wordWrap: 'normal',
                tabSize: 4,
                hyphens: 'none',
                fontFeatureSettings: 'normal',
                ...theme.typography.sizing(1, 'mono'),
            },
            'code': {
                fontSize: 'inherit',
                backgroundColor: theme.palette.custom.code.inline.color,
                color: theme.palette.custom.code.inline.contrastText.main,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0, 1),
            },
            // <div>
            // '.vscode-highlight-container'
            '.grvsc-container-wrapper': {
                overflow: 'hidden',
                borderRadius: theme.baseline.prePadding,
                // display: 'flex'
            },
            // <pre>
            // .vscode-highlight
            '.grvsc-container': {
                marginBottom: 0,
                // overflow: 'initial',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: theme.baseline.prePadding,
                flexGrow: 1,
            },
            // <code>  // Things could probably be easier if this wasn't in the tree :(
            // '.vscode-highlight-code'
            '.grvsc-code': {
                borderRadius: 0,
            },
            // <span>
            // '.vscode-highlight-line'
            '.grvsc-line': {
                display: 'inline-flex',
                flexGrow: 1,
                minWidth: '100%',
            },
            // <span>
            // '.vscode-highlight-line-highlighted': {
            '.grvsc-line-highlighted': {
                background: theme.palette.custom.code.block.lineHighlight.background.color,
                borderLeft: `${theme.spacing(1)}px solid ${theme.palette.custom.code.block.lineHighlight.accent.color}`,
                // We use rem here because we're in a pre block with monospace font.
                paddingLeft: `calc(1rem - ${theme.spacing(1)}px)`, // Minus border size
                paddingRight: '1rem',
                // marginLeft: `-${theme.typography.pxToRem(theme.baseline.prePadding)}`,
                // marginRight: `-${theme.typography.pxToRem(theme.baseline.prePadding)}`,
                marginLeft: `-${theme.baseline.prePadding}px`,
                marginRight: `-${theme.baseline.prePadding}px`,
                // This isn't a perfect solution as it overflows the <code> block. Using 'block' did fix the overflow,
                // but added a newline after each highlighted line... The <pre> causes the newlines in the markup to
                // render as newlines which causes a double spacing issue. It works with 'block' on overreacted.io.
                // Who the fuck knows.
                boxSizing: 'content-box',
                // display: 'block',
            },
            'a': {
                color: theme.palette.custom.links.default.color,
                textDecoration: 'none',
                boxShadow: '0 1px 0 0 currentColor',
                '&:hover, &:active': {
                    transition: theme.transitions.create(['box-shadow', 'color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: 75
                    }),
                    boxShadow: 'none',
                }
            },
            // Styling on gatsby-remark-autolink-headers links
            'a.anchor': {
                boxShadow: 'none',
                '& svg': {
                    color: theme.palette.custom.pages.blog.headingLink.color,
                    fill: 'currentColor',
                    stroke: 'currentColor'
                }
            },
            '.gatsby-remark-rewrap-images-container': {
                // There is no real way to handle the grid using theme.spacing() because consecutive `images-container`s
                // are not nested within another container. Because of limitations of CSS and lookbacks (e.g. no parent,
                // first-sibling-of-type, last-sibling-of-type, next, or prev selectors) we can't adjust the block
                // margin of the the rows independently. This becomes a problem for the first and last row in a grid
                // while keeping the single row grids at the std block margin bottom. We cheat here and just use the
                // block margin so it looks like it is spaced properly. I don't like how wide this makes the gutters,
                // but its the only option. Using marginTop would overwrite settings for other elements like our
                // headings :(
                width: `calc(100% + ${theme.baseline.blockMarginBottom}px)`,
                marginLeft: `-${theme.baseline.blockMarginBottom / 2}px`,
                marginRight: `-${theme.baseline.blockMarginBottom / 2}px`,
                marginBottom: theme.baseline.blockMarginBottom,
            },
            '.gatsby-remark-rewrap-images-image-item': {
                flex: 1,
                margin: 0,
                paddingLeft: `${theme.baseline.blockMarginBottom / 2}px`,
                paddingRight: `${theme.baseline.blockMarginBottom / 2}px`,
            },
            // '.gatsby-image-wrapper, .gatsby-resp-image-wrapper': {
            '.gatsby-resp-image-image, .gatsby-remark-rewrap-images-image': {
                boxShadow: theme.shadows[6],
                borderRadius: theme.shape.borderRadius
            },
            'a.gatsby-resp-image-link, a.gatsby-remark-rewrap-images-image-link': { boxShadow: 'none', },
            'ul, ol': theme.baseline.list,
            'li': theme.baseline.listItem,
            'td, th': {
                fontFeatureSettings: `${theme.typography.fontFeatureSettings.body},"tnum"`
            },
            'h1, h2, h3, h4, h5, h6': {
                textRendering: 'optimizeLegibility'
            },
            'h1': { ...theme.typography.h1, marginTop: '2rem' },
            'h2': { ...theme.typography.h2, marginTop: '2rem' },
            'h3': { ...theme.typography.h3, marginTop: '2rem' },
            'h4': { ...theme.typography.h4, marginTop: '2rem' },
            'h5': { ...theme.typography.h5, marginTop: '2rem' },
            'h6': { ...theme.typography.h6, marginTop: '2rem' },
        }
    };
});

export interface IBaselineP { }

export function Baseline(props: TReactFCP<IBaselineP>): TReactFCR {
    useStyles();
    return (
        <React.Fragment>
            {/* <MUICssBaseline /> */}
            <SEO />
            {props.children}
        </React.Fragment>
    );
}
