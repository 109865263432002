import * as React from 'react';
import { observer } from 'mobx-react';
import { Theme, AppBar, Toolbar, IconButton, Hidden, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import { UseStyles } from '../../../lib/types/mui';
import { TReactFCP, TReactFCR } from '../../../lib/types/utils';
import { useLayoutStore, ILayoutStore, TNavLayoutState } from '../../../lib/stores/layout';
import { Nav } from './Nav';
import { CreateCSSProperties } from '@material-ui/styles';
import { SiteLogo } from '../SiteLogo';
import { NAV_LINKS } from '../../../config/nav';

type ClassKey = 'root' | 'themeToggle' | 'menuIcon' | 'navContainer' | 'home' | 'titleContainer';
const useStyles = makeStyles<Theme, TNavLayoutState, ClassKey>((theme: Theme) => ({
    root(props: TNavLayoutState) {
        const styles: CreateCSSProperties<TNavLayoutState> = { flex: 0, marginBottom: theme.spacing(6) };
        if (props.isCollapsable) {
            const width: number = props.isCollapsed ? props.collapsedWidth : props.width;
            styles.marginLeft = `${width}px`;
            styles.width = `calc(100% - ${width}px)`;
            styles.transition = theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            });
        }
        return styles;
    },
    themeToggle: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1, 1),
        minWidth: 0,
        color: theme.palette.custom.header.default.contrastIconText.main,
        opacity: theme.palette.type === 'light' ? 0.6 : 'inherit'
    },
    menuIcon: {
        ...theme.typography.iconSizing(3),
        color: theme.palette.custom.header.default.contrastIconText.main,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        // Magic number comes from the total width of the menu button adjusted for its negative margin
        marginLeft: '-27px'
    },
    navContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
        alignItems: 'center',
        // marginLeft: theme.spacing(5),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto'
    },
    home: {
        color: theme.palette.custom.header.home.main,
        '&:focus, &:hover': {
            color: theme.palette.custom.header.home.hover
        }
    }
}));

export interface IHeaderP { }

export const Header: React.FC<IHeaderP> = observer((props: TReactFCP<IHeaderP>): TReactFCR => {
    const appLayout: ILayoutStore = useLayoutStore();
    // const { ui }: IStores = useStores();
    const styles: UseStyles<ClassKey> = useStyles(appLayout.currentNavLayout);
    const isDense: boolean = appLayout.currentHeaderLayout.isDense;
    const menuIcon: React.ReactNode = appLayout.currentNavLayout.isOpen ?
        <ArrowBackIcon className={styles.menuIcon} /> : <MenuIcon className={styles.menuIcon} />;
    const menuButton: React.ReactNode = !appLayout.currentNavLayout.isOpenable ? null : (
        <IconButton edge='start' size={isDense ? 'small' : 'medium'} onClick={appLayout.toggleNavOpen}>
            {menuIcon}
        </IconButton>
    );
    // const toggleTheme: ButtonOnClickFn = React.useCallback((e: ButtonOnClickEvent) => {
    //     e.stopPropagation();
    //     ui.changeTheme(ui.themeVariant === 'light' ? 'dark' : 'light');
    // }, [ui]);
    return (
        <AppBar
            className={styles.root}
            color='default'
            elevation={0}
            position={appLayout.currentHeaderLayout.position}
        >
            <Toolbar variant={isDense ? 'dense' : 'regular'}>
                {menuButton}
                <Hidden smUp>
                    <div className={styles.titleContainer}>
                        <SiteLogo className={styles.home} size='sm' />
                    </div>
                </Hidden>
                <Hidden xsDown>
                    <div className={styles.navContainer}>
                        <SiteLogo className={styles.home} size='md' />
                        <Nav variant='list' config={NAV_LINKS} />
                    </div>
                </Hidden>
                {/* <Button className={styles.themeToggle} onClick={toggleTheme}>
                        <Icon name='eclipse' weight='solid' size='lg' />
                    </Button> */}
            </Toolbar>
        </AppBar>
    );
});
