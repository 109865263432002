import '../../lib/datadog';
import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { App } from './App';

export type TPageP<TProps = {}> = {
    cprops: TProps;
    component: React.ComponentType<TProps>;
};

/**
 * Wraps a page or template component with the main App. Useful for layout and required to access contexts needed by
 * MUI for theming
 */
export function Page<Props = {}>(props: TReactFCP<TPageP<Props>>): TReactFCR {
    const { component: Component, cprops: componentProps }: TPageP<Props> = props;
    return <App><Component {...componentProps} /></App>;
}
