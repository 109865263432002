import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLSeoSiteMetadataQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { SchemaOrg, TSchemaOrgP } from './SchemaOrg';

// Replaces all newlines and whitespace to resemble a regular paragraph.
function trimString(s: string): string {
    return s.trim().replace(/[\s\n]+/g, ' ');
}

// Uses the file path to deduce mimetypes by the file extension
function imgMimetype(p: string): string | null {
    if (p.length === 0) return null;
    const splitPath: string[] = p.split('.');
    const ext: string = splitPath[splitPath.length - 1];
    switch (ext) {
        case 'png':
            return 'image/png';
        case 'jpeg':
        case 'jpg':
            return 'image/jpeg';
        case 'gif':
            return 'image/gif';
        case 'bmp':
            return 'image/bmp';
        case 'ico':
            return 'image/x-icon';
        case 'webp':
            return 'image/webp';
        default:
            return null;
    }
}

export type TSeoArticleMeta = {
    publishedTime: string;
    modifiedTime: string;
    expirationTime?: string;
    author: {
        name: string;
        image: string;
        website: string;
        twitterHandle: string;
    };
    timeToRead: number; // Minutes
    tags: string[];
};

export type TSeoImageP = {
    src: string;
    alt?: string;
    height?: number;
    width?: number;
};

export type TSeoP = {
    articleMeta?: TSeoArticleMeta;
    canonicalURL?: string;
    cardSize?: 'small' | 'large';   // Rule of thumb: Square or portrait images - `small`, landscape - `large`
    description?: string;           // Keep under <= 125 characters
    image?: TSeoImageP;             // TODO: Add optional properties
    keywords?: string[];
    slug?: string;
    title?: string;                 // The value for <title>
    seoTitle?: string;              // The value for OpenGraph, Twitter, etc. Defaults to value for `title`
    type?: 'website' | 'article',
};

/**
 * A note about the canonicalURL.
 *
 * Every page will have a <link> pointer to the canonical website. This enables us to use the deployment URL for images,
 * links, etc. Search engines will see the pointer and give treat the deployment as it was the canonical website/url.
 * What this means is that if search engines scrape multiple of our preview deployments our rankings won't be affected.
 */
export function SEO(props: TReactFCP<TSeoP>): TReactFCR {
    const { site, defaultPerson }: GQLSeoSiteMetadataQuery = useStaticQuery(graphql`
        query SEOSiteMetadata {
            site {
                siteMetadata {
                    analytics {
                        facebook {
                            appId
                        }
                    }
                    defaultDescription: description
                    defaultImage: imageSeo {
                        src
                        width
                        height
                    }
                    defaultKeywords: keywords
                    logo {
                        src
                        width
                        height
                    }
                    siteCanonicalUrl
                    siteName
                    social {
                        twitter
                    }
                    defaultTitle: titleSeo
                    siteUrl
                }
            }
            defaultPerson: authorYaml(aid: {eq: "johnrichter"}) {
                image {
                    childImageSharp {
                        original {
                            height
                            src
                            width
                        }
                    }
                }
            }
        }
    `);
    const {
        siteUrl,
        siteCanonicalUrl,
        siteName,
        analytics,
        defaultTitle,
        defaultImage,
        defaultDescription,
        defaultKeywords,
        social,
        logo,
    } = site!.siteMetadata!;
    const siteLogo: string = `${siteUrl}${logo!.src!}`;
    const publisherTwitterHandle: string = social!.twitter!;
    const {
        articleMeta,
        slug = '/', // Must default to root for SEO reasons
        image = { src: defaultImage!.src!, width: defaultImage!.width!, height: defaultImage!.height! },
        keywords = defaultKeywords ?? [],
        cardSize = 'large',
        type = 'website',
        canonicalURL = `${siteCanonicalUrl}${slug}`,
        description: rawDescription = defaultDescription ?? '',
        title: rawTitle = defaultTitle ?? '',
        seoTitle: rawSeoTitle = rawTitle
    }: TSeoP = props;
    const description = trimString(rawDescription);
    const title = trimString(rawTitle);
    const seoTitle = trimString(rawSeoTitle);
    const schemaOrgProps: TSchemaOrgP = {
        site: {
            logo: siteLogo!,
            url: siteUrl!,
            name: siteName!,
            description: defaultDescription!
        },
        page: {
            // person: slug === '/' ? undefined : {
            person: slug !== '/' ? undefined : {
                name: siteName!,
                image: `${siteUrl}${defaultPerson!.image!.childImageSharp!.original!.src!}`,
                brandLogo: siteLogo!,
                website: siteUrl!
            },
            description,
            canonicalURL,
            title,
        },
        blogPost: type !== 'article' ? undefined : {
            title: seoTitle,
            defaultTitle: `A blog post by ${siteName}`,
            image: image.src !== defaultImage!.src! ? `${siteUrl}${image.src}` : undefined,
            description,
            author: {
                ...articleMeta!.author,
                image: `${siteUrl}${articleMeta!.author.image}`
            },
            publishDate: articleMeta!.publishedTime,
            modifiedDate: articleMeta!.modifiedTime,
            language: 'en',
            keywords: articleMeta!.tags,
            timeToRead: articleMeta!.timeToRead
        }
    };

    function renderHTML(): React.ReactNode {
        const nodes: React.ReactNode[] = [];
        // Avoid repeated titles due to title template in Helmet. E.g. `John Richter | John Richter`
        if (title && title !== siteName) {
            nodes.push(<title key='title'>{title}</title>);
        }
        if (canonicalURL) {
            nodes.push(<link key='link:canonical' rel='canonical' href={canonicalURL} />);
        }
        if (keywords) {
            nodes.push(<meta key='keywords' name="keywords" content={keywords.join()} />);
        }
        return nodes;
    }

    function renderOpenGraph(): React.ReactNode {
        const nodes: React.ReactNode[] = [];
        if (canonicalURL) {
            nodes.push(<meta key='og:url' property='og:url' content={canonicalURL} />);
        }
        if (description) {
            nodes.push(<meta key='og:description' property='og:description' content={description} />);
        }
        if (image) {
            const mimetype: string | null = imgMimetype(image.src);
            nodes.push(<meta key='og:image' property='og:image' content={`${siteUrl}${image.src}`} />);
            nodes.push(<meta key='og:image:url' property='og:image:url' content={`${siteUrl}${image.src}`} />);
            nodes.push(
                <meta key='og:image:secure_url' property='og:image:secure_url' content={`${siteUrl}${image.src}`} />
            );
            if (image.width !== undefined) {
                nodes.push(<meta key='og:image:width' property='og:image:width' content={`${image.width}`} />);
            }
            if (image.height !== undefined) {
                nodes.push(<meta key='og:image:height' property='og:image:height' content={`${image.height}`} />);
            }
            if (mimetype !== null) {
                nodes.push(<meta key='og:image:type' property='og:image:type' content={mimetype} />);
            }
        }
        if (seoTitle) {
            nodes.push(<meta key='og:title' property='og:title' content={seoTitle} />);
        }
        if (type) {
            nodes.push(<meta key='og:type' property='og:type' content={type} />);
            switch (type) {
                case 'article':
                    if (articleMeta) {
                        if (articleMeta.publishedTime) {
                            const key: string = 'article:published_time';
                            nodes.push(<meta key={`og:${key}`} property={key} content={articleMeta.publishedTime} />);
                        }
                        if (articleMeta.modifiedTime) {
                            const key: string = 'article:modified_time';
                            nodes.push(<meta key={`og:${key}`} property={key} content={articleMeta.modifiedTime} />);
                        }
                        if (articleMeta.expirationTime) {
                            const key: string = 'article:expiration_time';
                            nodes.push(<meta key={`og:${key}`} property={key} content={articleMeta.expirationTime} />);
                        }
                        if (articleMeta.author) {
                            const key: string = 'article:author';
                            nodes.push(<meta key={`og:${key}`} property={key} content={articleMeta.author.name} />);
                        }
                    }
                    break;
                case 'website':
                default:
                    break;
            }
        }
        if (siteName) {
            nodes.push(<meta key='og:site_name' property='og:site_name' content={siteName} />);
        }
        return nodes;
    }

    function renderFacebook(): React.ReactNode {
        const nodes: React.ReactNode[] = [];
        if (analytics!.facebook!.appId) {
            nodes.push(<meta key='fb:app_id' property='fb:app_id' content={analytics!.facebook!.appId} />);
        }
        return nodes;
    }

    function renderTwitter(): React.ReactNode {
        const nodes: React.ReactNode[] = [
            // Only two card sizes at the moment. Defaults to large.
            <meta
                key='twitter:card'
                name='twitter:card'
                content={cardSize === 'small' ? 'summary' : 'summary_large_image'}
            />
        ];
        if (publisherTwitterHandle) {
            nodes.push(
                <meta key='twitter:site' name='twitter:site' content={`@${publisherTwitterHandle}`} />
                // <meta key='twitter:site:id' name='twitter:site:id' content={tw.site.userID} />
            );
        }
        if (articleMeta || publisherTwitterHandle) {
            const authorTwitterHandle: string = (articleMeta && articleMeta.author.twitterHandle) ?
                articleMeta.author.twitterHandle : publisherTwitterHandle || '';
            nodes.push(
                <meta
                    key='twitter:creator'
                    name='twitter:creator'
                    content={`@${authorTwitterHandle}`}
                />
                // <meta key='twitter:creator:id' name='twitter:creator:id' content={tw.creator.userID} />
            );
        }
        if (description) {
            nodes.push(<meta key='twitter:description' name='twitter:description' content={description} />);
        }
        if (image) {
            nodes.push(
                <meta key='twitter:image' name='twitter:image' content={`${siteUrl}${image.src}`} />,
            );
            if (image.alt) {
                nodes.push(
                    <meta key='twitter:image:alt' name='twitter:image:alt' content={image.alt} />
                );
            }
        }
        // if (tw.avPlayer) {
        //     nodes.push(
        //         <meta key='twitter:player' name='twitter:player' content={tw.avPlayer.url} />,
        //         <meta key='twitter:player:width' name='twitter:player:width' content={tw.avPlayer.width} />,
        //         <meta key='twitter:player:height' name='twitter:player:height' content={tw.avPlayer.height} />
        //     );
        //     if (tw.avPlayer.streamURL) {
        //         nodes.push(
        //             <meta key='twitter:player:stream' name='twitter:player:stream' content={tw.avPlayer.streamURL} />
        //         );
        //     }
        // }
        // if (apps) {
        //     if (apps.apple) {
        //         if (apps.apple.iphone) {
        //             const id: string = 'twitter:app:id:iphone';
        //             const name: string = 'twitter:app:name:iphone';
        //             const url: string = 'twitter:app:url:iphone';
        //             nodes.push(
        //                 <meta key={id} name={id} content={apps.apple.iphone.appID} />,
        //                 <meta key={name} name={name} content={apps.apple.iphone.name} />,
        //                 <meta key={url} name={url} content={apps.apple.iphone.urlScheme} />
        //             );
        //         }
        //         if (apps.apple.ipad) {
        //             const id: string = 'twitter:app:id:ipad';
        //             const name: string = 'twitter:app:name:ipad';
        //             const url: string = 'twitter:app:url:ipad';
        //             nodes.push(
        //                 <meta key={id} name={id} content={apps.apple.ipad.appID} />,
        //                 <meta key={name} name={name} content={apps.apple.ipad.name} />,
        //                 <meta key={url} name={url} content={apps.apple.ipad.urlScheme} />
        //             );
        //         }
        //     }
        //     if (apps.google) {
        //         if (apps.google.android) {
        //             const id: string = 'twitter:app:id:googleplay';
        //             const name: string = 'twitter:app:name:googleplay';
        //             const url: string = 'twitter:app:url:googleplay';
        //             nodes.push(
        //                 <meta key={id} name={id} content={apps.google.android.appID} />,
        //                 <meta key={name} name={name} content={apps.google.android.name} />,
        //                 <meta key={url} name={url} content={apps.google.android.urlScheme} />
        //             );
        //         }
        //     }
        // }
        return nodes;
    }

    return (
        <React.Fragment>
            <Helmet defer={false} defaultTitle={siteName!} titleTemplate={`${siteName} | %s`}>
                {renderHTML()}
                {renderOpenGraph()}
                {renderFacebook()}
                {renderTwitter()}
            </Helmet>
            <SchemaOrg {...schemaOrgProps} />
        </React.Fragment>
    );
}
