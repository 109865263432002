import * as React from 'react';
import { FontAwesomeIcon, Props as FAProps } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faLinkedin, faFacebookSquare, faInstagram, faYoutube, faSpotify, faGithub, faGitlab, faTwitter, faLinkedinIn, faYoutubeSquare, faGithubSquare, faFacebookF, faKeybase, faGitAlt, faTumblr, faRedditAlien } from '@fortawesome/free-brands-svg-icons';
import { faTags, faClock, faCalendar, faBookReader, faGlasses, faBookOpen, faTools, faFingerprint, faRss, faAt, faFlagCheckered, faBriefcase, faIdBadge, faExternalLinkAlt, faGlobe, faGlobeAmericas, faCodeBranch, faLongArrowAltRight, faCaretRight, faArrowRight, faChevronRight, faAngleDoubleRight, faCheckCircle, faCheck, faHeartbeat, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { TReactFCR, TReactFCP } from '../lib/types/utils';

export type TBaseIconProps = { className?: string; };
export type FAIconProps = Omit<FAProps, 'icon'>;
export type TIconProps = TBaseIconProps | FAIconProps;

export type IconProp<TProps extends {} = {}> = {
    Component: React.ComponentType<TIconProps | TBaseIconProps>;
    props?: TProps | TIconProps;
};

export function TwitterSquareIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faTwitterSquare} />;
}

export function TwitterIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faTwitter} />;
}

export function FacebookSquareIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faFacebookSquare} />;
}

export function FacebookIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faFacebookF} />;
}

export function RedditIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faRedditAlien} />;
}

export function LinkedInSquareIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faLinkedin} />;
}

export function LinkedInIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faLinkedinIn} />;
}

export function InstagramIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faInstagram} />;
}

export function YoutubeSquareIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faYoutubeSquare} />;
}

export function YoutubeIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faYoutube} />;
}

export function SpotifyIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faSpotify} />;
}

export function GithubSquareIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGithubSquare} />;
}

export function GithubIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGithub} />;
}

export function GitlabIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGitlab} />;
}

export function KeybaseIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faKeybase} />;
}

export function TumblrIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faTumblr} />;
}

export function FitbitIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faHeartbeat} />;
}

export function TagsIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faTags} />;
}

export function ClockIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faClock} />;
}

export function CalendarIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faCalendar} />;
}

export function BookReaderIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faBookReader} />;
}

export function BookOpenIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faBookOpen} />;
}

export function IDCardIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faIdCard} />;
}

export function GlassesIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGlasses} />;
}

export function ToolsIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faTools} />;
}

export function FingerprintIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faFingerprint} />;
}

export function RssIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faRss} />;
}

export function AtIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faAt} />;
}

export function CheckeredFlagIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faFlagCheckered} />;
}

export function BriefcaseIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faBriefcase} />;
}

export function IDBadgeIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faIdBadge} />;
}

export function ExternalLinkIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faExternalLinkAlt} />;
}

export function GlobeIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGlobe} />;
}

export function GlobeAmericasIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGlobeAmericas} />;
}

export function GitIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faGitAlt} />;
}

export function CodeBranchIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faCodeBranch} />;
}

export function ArrowRightIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faArrowRight} />;
}

export function LongArrowRightIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faLongArrowAltRight} />;
}

export function CaretRightIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faCaretRight} />;
}

export function ChevronRightIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faChevronRight} />;
}

export function DoubleChevronRightIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faAngleDoubleRight} />;
}

export function CheckIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faCheck} />;
}

export function CheckCircleIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faCheckCircle} />;
}

export function HeartbeatIcon(props: TReactFCP<FAIconProps>): TReactFCR {
    return <FontAwesomeIcon {...props} icon={faHeartbeat} />;
}