import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../../lib/types/utils';
import { UseStyles } from '../../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';

type ClassKey = 'content';

const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    content: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
    }
}));

export function Content(props: TReactFCP): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return <div className={styles.content}>{props.children}</div>;
}
