import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { TSocialLinkP, SocialLink } from './SocialLink';
import { List, ListItem, Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../lib/types/mui';
import { CreateCSSProperties } from '@material-ui/styles';
import { ClassNameMap } from '@material-ui/styles/withStyles';

type StyleProps = { inline: boolean; enableText: boolean; };
type ClassKey = 'list' | 'listItem';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    list(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = {};
        if (props.inline) {
            style.display = 'inline-flex';
            style.flexWrap = 'wrap';
            style.alignItems = 'center';
            style.justifyContent = 'center';
        }
        return style;
    },
    listItem(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = { padding: 0, margin: 0 };
        if (props.inline) {
            style.display = 'inline-block';
            style.width = 'auto';
            style['&:not(:last-child)'] = { marginRight: theme.spacing(props.enableText ? 4 : 2) };
        }
        return style;
    }
}));

export type TSocialListP = {
    className?: string;
    classes?: Partial<ClassNameMap<ClassKey>>;
    inline?: boolean;
    SocialLinkProps?: Omit<TSocialLinkP, 'variant'>;
};

export function SocialList(props: TReactFCP<TSocialListP>): TReactFCR {
    const { className, inline = false, classes = {}, SocialLinkProps = {} }: TSocialListP = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({
        inline, enableText: SocialLinkProps.enableText || false
    });
    const listStyle: string = clsx(styles.list, className, classes.list);
    const listItemStyle: string = clsx(styles.listItem, classes.listItem);
    return (
        <List disablePadding className={listStyle}>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='email' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='linkedin' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='gitlab' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='github' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='twitter' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='facebook' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='instagram' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='reddit' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='spotify' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='youtube' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='keybase' {...SocialLinkProps} />
            </ListItem>
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='fitbit' {...SocialLinkProps} />
            </ListItem>
            {/* <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='tumblr' {...SocialLinkProps} />
            </ListItem> */}
            <ListItem className={listItemStyle} disableGutters>
                <SocialLink variant='blogRss' {...SocialLinkProps} />
            </ListItem>
        </List>
    );
}