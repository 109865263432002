import createSpacing, { Spacing } from '@material-ui/core/styles/createSpacing';

export function spacing(step: number): number {
    switch (step) {
        case 1: return 4;
        case 2: return 8;
        case 3: return 12;
        case 4: return 16;
        case 5: return 24;
        case 6: return 32;
        case 7: return 48;
        case 8: return 64;
        case 9: return 96;
        case 10: return 128;
        case 11: return 192;
        case 12: return 256;
        default: return 0;
    }
}

export const SPACING: Spacing = createSpacing(spacing);
