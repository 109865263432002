import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../../../lib/types/utils';
import { observer } from 'mobx-react';
import { Theme, SwipeableDrawer, IconButton, List, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { UseStyles } from '../../../../lib/types/mui';
import { TNavLayoutState, ILayoutStore, useLayoutStore } from '../../../../lib/stores/layout';
import { CreateCSSProperties } from '@material-ui/styles';
import { NavLinkConfig, NavLink } from './NavLink';
import { SiteLogo } from '../../SiteLogo';

type StyleProps = TNavLayoutState;

type ClassKeys = 'header' | 'content' | 'container' | 'collapse';
const useStyles = makeStyles<Theme, StyleProps, ClassKeys>((theme: Theme) => ({
    container(props: StyleProps) {
        const styles: CreateCSSProperties<StyleProps> = {};
        styles.width = props.isCollapsed ? `${props.collapsedWidth}px` : `${props.width}px`;
        styles.transition = theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        });
        return styles;
    },
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    content: {
        flex: '1 0 auto',
        overflowX: 'hidden',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    collapse: { borderTop: `1px solid ${theme.palette.divider}`, borderRadius: 0 },
}));

export type TNavDrawerP = {
    config: NavLinkConfig[];
    header?: React.ReactNode;
};

export const NavDrawer: React.FC<TNavDrawerP> = observer((props: TReactFCP<TNavDrawerP>): TReactFCR => {
    const store: ILayoutStore = useLayoutStore();
    const styles: UseStyles<ClassKeys, TNavLayoutState> = useStyles(store.currentNavLayout);
    const links: React.ReactNode[] = props.config.map((c: NavLinkConfig) => (
        <NavLink key={c.path} variant='drawer' config={c} />
    ));
    const header: React.ReactNode = (
        <React.Fragment>
            <div className={styles.header}><SiteLogo /></div>
            {/* <Divider /> */}
        </React.Fragment>
    );
    const collapseButton: React.ReactNode = !store.currentNavLayout.isCollapsable ? null : (
        <IconButton className={styles.collapse} color='inherit' size='medium' onClick={store.toggleNavCollapse}>
            {store.currentNavLayout.isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
    );
    return (
        <nav>
            <SwipeableDrawer
                classes={{ paper: styles.container }}
                elevation={0}
                open={store.currentNavLayout.isOpen}
                onOpen={store.toggleNavOpen}
                onClose={store.toggleNavOpen}
                variant={store.currentNavLayout.isCollapsable ? 'persistent' : 'temporary'}
            >
                {header}
                <div className={styles.content}>
                    <List>{links}</List>
                </div>
                {collapseButton}
            </SwipeableDrawer>
        </nav>
    );
});
