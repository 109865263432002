import { NavLinkConfig } from '../components/app/layout/Nav/NavLink';
import { BookOpenIcon, FingerprintIcon, CheckeredFlagIcon, BriefcaseIcon, IDCardIcon } from '../components/FAIcons';

export const NAV_LINKS: NavLinkConfig[] = [
    { path: '/blog/', label: 'Blog', icon: BookOpenIcon },
    { path: '/career/', label: 'Career', icon: BriefcaseIcon },
    { path: '/driving/', label: 'Driving', icon: CheckeredFlagIcon },
    { path: '/about/', label: 'About', icon: FingerprintIcon },
];

export const FOOTER_NAV_LINKS: NavLinkConfig[] = [
    { path: '/projects/', label: 'Projects', icon: BookOpenIcon },
    { path: '/social/', label: 'Social', icon: IDCardIcon }
];
