import { Theme, createMuiTheme } from '@material-ui/core';
import { LightUIPalette, DarkUIPalette, BaseUIPalette, PaletteColor, SocialMediaBrandUIPalette } from './palettes';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';
import { TYPOGRAPHY } from './typography';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { SPACING } from './spacing';
import { SHADOWS } from './shadows';
import { SHAPE } from './shape';
import { BASELINE } from './baseline';
import { BREAKPOINTS } from './breakpoints';
import transitions from '@material-ui/core/styles/transitions';

export type TThemeVariant = 'light' | 'dark';

const WHITE: string = '#FFFFFF';
const BLACK: string = '#000000';
const BASE_PALETTE: BaseUIPalette = { black: BLACK, white: WHITE };
const BASE_MUI_THEME: ThemeOptions = {
    baseline: BASELINE,
    breakpoints: BREAKPOINTS,
    typography: TYPOGRAPHY,
    spacing: SPACING,
    shape: SHAPE,
    shadows: SHADOWS
};

/**
 * Common Palette
 */

const SOCIAL_MEDIA_BRANDS: SocialMediaBrandUIPalette = {
    linkedIn: 'hsl(200.6, 100%, 35%)',
    twitter: 'hsl(202.8, 89%, 53%)',
    facebook: 'hsl(220.6, 44%, 42%)',
    instagram: 'hsl(312.5, 65%, 46%)',
    youtube: 'hsl(0, 100%, 50%)',
    spotify: 'hsl(141.4, 76%, 48%)',
    github: 'hsl(0, 0%, 20%)',
    gitlab: 'hsl(19.9, 97%, 57%)',
    reddit: 'hsl(16.2, 100%, 50%)',
    keybase: 'hsl(208, 100%, 60%)',
    tumblr: 'hsl(213.8, 26.9%, 28.4%)',
    fitbit: 'hsl(181, 50.4%, 53.3%)',
    jawbone: 'hsl(0, 0%, 0%)',
    runkeeper: 'hsl(184.9, 68%, 51%)',
};

/**
 * Light Theme
 */
const LIGHT_PALETTE_NEUTRAL_50: string = 'hsl(210, 36%, 96%)';
const LIGHT_PALETTE_NEUTRAL_100: string = 'hsl(212, 33%, 89%)';
const LIGHT_PALETTE_NEUTRAL_200: string = 'hsl(210, 31%, 80%)';
const LIGHT_PALETTE_NEUTRAL_300: string = 'hsl(211, 27%, 70%)';
const LIGHT_PALETTE_NEUTRAL_400: string = 'hsl(209, 23%, 60%)';
const LIGHT_PALETTE_NEUTRAL_500: string = 'hsl(210, 22%, 49%)';
const LIGHT_PALETTE_NEUTRAL_600: string = 'hsl(209, 28%, 39%)';
const LIGHT_PALETTE_NEUTRAL_700: string = 'hsl(209, 34%, 30%)';
const LIGHT_PALETTE_NEUTRAL_800: string = 'hsl(211, 39%, 23%)';
const LIGHT_PALETTE_NEUTRAL_900: string = 'hsl(209, 61%, 16%)';
const LIGHT_PALETTE_NEUTRAL: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_NEUTRAL_50,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_700,
            light: LIGHT_PALETTE_NEUTRAL_800,
            main: LIGHT_PALETTE_NEUTRAL_900,
            dark: LIGHT_PALETTE_NEUTRAL_900,
            darker: LIGHT_PALETTE_NEUTRAL_900
        },
        contrastIconText: {
            lighter: LIGHT_PALETTE_NEUTRAL_500,
            light: LIGHT_PALETTE_NEUTRAL_600,
            main: LIGHT_PALETTE_NEUTRAL_700,
            dark: LIGHT_PALETTE_NEUTRAL_800,
            darker: LIGHT_PALETTE_NEUTRAL_800
        }
    },
    100: {
        color: LIGHT_PALETTE_NEUTRAL_100,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_600,
            light: LIGHT_PALETTE_NEUTRAL_700,
            main: LIGHT_PALETTE_NEUTRAL_800,
            dark: LIGHT_PALETTE_NEUTRAL_900,
            darker: LIGHT_PALETTE_NEUTRAL_900
        },
        contrastIconText: {
            lighter: LIGHT_PALETTE_NEUTRAL_500,
            light: LIGHT_PALETTE_NEUTRAL_600,
            main: LIGHT_PALETTE_NEUTRAL_700,
            dark: LIGHT_PALETTE_NEUTRAL_800,
            darker: LIGHT_PALETTE_NEUTRAL_900
        }
    },
    200: {
        color: LIGHT_PALETTE_NEUTRAL_200,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_600,
            light: LIGHT_PALETTE_NEUTRAL_700,
            main: LIGHT_PALETTE_NEUTRAL_800,
            dark: LIGHT_PALETTE_NEUTRAL_900,
            darker: LIGHT_PALETTE_NEUTRAL_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_NEUTRAL_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_NEUTRAL_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_NEUTRAL_500,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_50,
            light: LIGHT_PALETTE_NEUTRAL_50,
            main: LIGHT_PALETTE_NEUTRAL_100,
            dark: LIGHT_PALETTE_NEUTRAL_200,
            darker: LIGHT_PALETTE_NEUTRAL_300
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_NEUTRAL_600,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_50,
            light: LIGHT_PALETTE_NEUTRAL_100,
            main: LIGHT_PALETTE_NEUTRAL_200,
            dark: LIGHT_PALETTE_NEUTRAL_300,
            darker: LIGHT_PALETTE_NEUTRAL_400
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_NEUTRAL_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_NEUTRAL_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_NEUTRAL_900,
        contrastText: {
            lighter: LIGHT_PALETTE_NEUTRAL_50,
            light: LIGHT_PALETTE_NEUTRAL_100,
            main: LIGHT_PALETTE_NEUTRAL_200,
            dark: LIGHT_PALETTE_NEUTRAL_300,
            darker: LIGHT_PALETTE_NEUTRAL_400
        },
        contrastIconText: {
            lighter: LIGHT_PALETTE_NEUTRAL_100,
            light: LIGHT_PALETTE_NEUTRAL_200,
            main: LIGHT_PALETTE_NEUTRAL_300,
            dark: LIGHT_PALETTE_NEUTRAL_400,
            darker: LIGHT_PALETTE_NEUTRAL_500
        }
    },
};
const LIGHT_PALETTE_LIGHT_BLUE_50: string = 'hsl(195, 100%, 95%)';
const LIGHT_PALETTE_LIGHT_BLUE_100: string = 'hsl(195, 100%, 85%)';
const LIGHT_PALETTE_LIGHT_BLUE_200: string = 'hsl(195, 97%, 75%)';
const LIGHT_PALETTE_LIGHT_BLUE_300: string = 'hsl(196, 94%, 67%)';
const LIGHT_PALETTE_LIGHT_BLUE_400: string = 'hsl(197, 92%, 61%)';
const LIGHT_PALETTE_LIGHT_BLUE_500: string = 'hsl(199, 84%, 55%)';
const LIGHT_PALETTE_LIGHT_BLUE_600: string = 'hsl(201, 79%, 46%)';
const LIGHT_PALETTE_LIGHT_BLUE_700: string = 'hsl(202, 83%, 41%)';
const LIGHT_PALETTE_LIGHT_BLUE_800: string = 'hsl(203, 87%, 34%)';
const LIGHT_PALETTE_LIGHT_BLUE_900: string = 'hsl(204, 96%, 27%)';
const LIGHT_PALETTE_LIGHT_BLUE: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_LIGHT_BLUE_50,
        contrastText: {
            lighter: LIGHT_PALETTE_LIGHT_BLUE_700,
            light: LIGHT_PALETTE_LIGHT_BLUE_800,
            main: LIGHT_PALETTE_LIGHT_BLUE_900,
            dark: LIGHT_PALETTE_LIGHT_BLUE_900,
            darker: LIGHT_PALETTE_LIGHT_BLUE_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    100: {
        color: LIGHT_PALETTE_LIGHT_BLUE_100,
        contrastText: {
            lighter: LIGHT_PALETTE_LIGHT_BLUE_700,
            light: LIGHT_PALETTE_LIGHT_BLUE_800,
            main: LIGHT_PALETTE_LIGHT_BLUE_900,
            dark: LIGHT_PALETTE_LIGHT_BLUE_900,
            darker: LIGHT_PALETTE_LIGHT_BLUE_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_LIGHT_BLUE_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_LIGHT_BLUE_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_LIGHT_BLUE_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_LIGHT_BLUE_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_LIGHT_BLUE_600,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_LIGHT_BLUE_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_LIGHT_BLUE_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_LIGHT_BLUE_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE_CYAN_50: string = 'hsl(186, 100%, 94%)';
const LIGHT_PALETTE_CYAN_100: string = 'hsl(185, 94%, 87%)';
const LIGHT_PALETTE_CYAN_200: string = 'hsl(184, 80%, 74%)';
const LIGHT_PALETTE_CYAN_300: string = 'hsl(184, 65%, 59%)';
const LIGHT_PALETTE_CYAN_400: string = 'hsl(185, 57%, 50%)';
const LIGHT_PALETTE_CYAN_500: string = 'hsl(185, 62%, 45%)';
const LIGHT_PALETTE_CYAN_600: string = 'hsl(184, 77%, 34%)';
const LIGHT_PALETTE_CYAN_700: string = 'hsl(185, 81%, 29%)';
const LIGHT_PALETTE_CYAN_800: string = 'hsl(185, 84%, 25%)';
const LIGHT_PALETTE_CYAN_900: string = 'hsl(184, 91%, 17%)';
const LIGHT_PALETTE_CYAN: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_CYAN_50,
        contrastText: {
            lighter: LIGHT_PALETTE_CYAN_700,
            light: LIGHT_PALETTE_CYAN_800,
            main: LIGHT_PALETTE_CYAN_900,
            dark: LIGHT_PALETTE_CYAN_900,
            darker: LIGHT_PALETTE_CYAN_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    100: {
        color: LIGHT_PALETTE_CYAN_100,
        contrastText: {
            lighter: LIGHT_PALETTE_CYAN_700,
            light: LIGHT_PALETTE_CYAN_800,
            main: LIGHT_PALETTE_CYAN_900,
            dark: LIGHT_PALETTE_CYAN_900,
            darker: LIGHT_PALETTE_CYAN_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_CYAN_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_CYAN_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_CYAN_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_CYAN_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_CYAN_600,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_CYAN_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_CYAN_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_CYAN_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE_PINK_50: string = 'hsl(341, 100%, 95%)';
const LIGHT_PALETTE_PINK_100: string = 'hsl(338, 100%, 86%)';
const LIGHT_PALETTE_PINK_200: string = 'hsl(336, 100%, 77%)';
const LIGHT_PALETTE_PINK_300: string = 'hsl(334, 86%, 67%)';
const LIGHT_PALETTE_PINK_400: string = 'hsl(330, 79%, 56%)';
const LIGHT_PALETTE_PINK_500: string = 'hsl(328, 85%, 46%)';
const LIGHT_PALETTE_PINK_600: string = 'hsl(326, 90%, 39%)';
const LIGHT_PALETTE_PINK_700: string = 'hsl(324, 93%, 33%)';
const LIGHT_PALETTE_PINK_800: string = 'hsl(322, 93%, 27%)';
const LIGHT_PALETTE_PINK_900: string = 'hsl(320, 100%, 19%)';
const LIGHT_PALETTE_PINK: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_PINK_50,
        contrastText: {
            lighter: LIGHT_PALETTE_PINK_700,
            light: LIGHT_PALETTE_PINK_800,
            main: LIGHT_PALETTE_PINK_900,
            dark: LIGHT_PALETTE_PINK_900,
            darker: LIGHT_PALETTE_PINK_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    100: {
        color: LIGHT_PALETTE_PINK_100,
        contrastText: {
            lighter: LIGHT_PALETTE_PINK_700,
            light: LIGHT_PALETTE_PINK_800,
            main: LIGHT_PALETTE_PINK_900,
            dark: LIGHT_PALETTE_PINK_900,
            darker: LIGHT_PALETTE_PINK_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_PINK_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_PINK_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_PINK_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_PINK_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_PINK_600,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_PINK_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_PINK_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_PINK_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE_RED_50: string = 'hsl(360, 100%, 95%)';
const LIGHT_PALETTE_RED_100: string = 'hsl(360, 100%, 87%)';
const LIGHT_PALETTE_RED_200: string = 'hsl(360, 100%, 80%)';
const LIGHT_PALETTE_RED_300: string = 'hsl(360, 91%, 69%)';
const LIGHT_PALETTE_RED_400: string = 'hsl(360, 83%, 62%)';
const LIGHT_PALETTE_RED_500: string = 'hsl(356, 75%, 53%)';
const LIGHT_PALETTE_RED_600: string = 'hsl(354, 85%, 44%)';
const LIGHT_PALETTE_RED_700: string = 'hsl(352, 90%, 35%)';
const LIGHT_PALETTE_RED_800: string = 'hsl(350, 94%, 28%)';
const LIGHT_PALETTE_RED_900: string = 'hsl(348, 94%, 20%)';
const LIGHT_PALETTE_RED: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_RED_50,
        contrastText: {
            lighter: LIGHT_PALETTE_RED_700,
            light: LIGHT_PALETTE_RED_800,
            main: LIGHT_PALETTE_RED_900,
            dark: LIGHT_PALETTE_RED_900,
            darker: LIGHT_PALETTE_RED_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    100: {
        color: LIGHT_PALETTE_RED_100,
        contrastText: {
            lighter: LIGHT_PALETTE_RED_700,
            light: LIGHT_PALETTE_RED_800,
            main: LIGHT_PALETTE_RED_900,
            dark: LIGHT_PALETTE_RED_900,
            darker: LIGHT_PALETTE_RED_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_RED_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_RED_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_RED_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_RED_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_RED_600,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_RED_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_RED_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_RED_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE_YELLOW_50: string = 'hsl(49, 100%, 96%)';
const LIGHT_PALETTE_YELLOW_100: string = 'hsl(48, 100%, 88%)';
const LIGHT_PALETTE_YELLOW_200: string = 'hsl(48, 95%, 76%)';
const LIGHT_PALETTE_YELLOW_300: string = 'hsl(48, 94%, 68%)';
const LIGHT_PALETTE_YELLOW_400: string = 'hsl(44, 92%, 63%)';
const LIGHT_PALETTE_YELLOW_500: string = 'hsl(42, 87%, 55%)';
const LIGHT_PALETTE_YELLOW_600: string = 'hsl(36, 77%, 49%)';
const LIGHT_PALETTE_YELLOW_700: string = 'hsl(29, 80%, 44%)';
const LIGHT_PALETTE_YELLOW_800: string = 'hsl(22, 82%, 39%)';
const LIGHT_PALETTE_YELLOW_900: string = 'hsl(15, 86%, 30%)';
const LIGHT_PALETTE_YELLOW: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_YELLOW_50,
        contrastText: {
            lighter: LIGHT_PALETTE_YELLOW_700,
            light: LIGHT_PALETTE_YELLOW_800,
            main: LIGHT_PALETTE_YELLOW_900,
            dark: LIGHT_PALETTE_YELLOW_900,
            darker: LIGHT_PALETTE_YELLOW_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    100: {
        color: LIGHT_PALETTE_YELLOW_100,
        contrastText: {
            lighter: LIGHT_PALETTE_YELLOW_700,
            light: LIGHT_PALETTE_YELLOW_800,
            main: LIGHT_PALETTE_YELLOW_900,
            dark: LIGHT_PALETTE_YELLOW_900,
            darker: LIGHT_PALETTE_YELLOW_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_YELLOW_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_YELLOW_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_YELLOW_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_YELLOW_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_YELLOW_600,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    700: {
        color: LIGHT_PALETTE_YELLOW_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_YELLOW_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_YELLOW_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE_TEAL_50: string = 'hsl(152, 68%, 96%)';
const LIGHT_PALETTE_TEAL_100: string = 'hsl(154, 75%, 87%)';
const LIGHT_PALETTE_TEAL_200: string = 'hsl(156, 73%, 74%)';
const LIGHT_PALETTE_TEAL_300: string = 'hsl(158, 58%, 62%)';
const LIGHT_PALETTE_TEAL_400: string = 'hsl(160, 51%, 49%)';
const LIGHT_PALETTE_TEAL_500: string = 'hsl(162, 63%, 41%)';
const LIGHT_PALETTE_TEAL_600: string = 'hsl(164, 71%, 34%)';
const LIGHT_PALETTE_TEAL_700: string = 'hsl(166, 72%, 28%)';
const LIGHT_PALETTE_TEAL_800: string = 'hsl(168, 80%, 23%)';
const LIGHT_PALETTE_TEAL_900: string = 'hsl(170, 97%, 15%)';
const LIGHT_PALETTE_TEAL: PaletteColor = {
    50: {
        color: LIGHT_PALETTE_TEAL_50,
        contrastText: {
            lighter: LIGHT_PALETTE_TEAL_700,
            light: LIGHT_PALETTE_TEAL_800,
            main: LIGHT_PALETTE_TEAL_900,
            dark: LIGHT_PALETTE_TEAL_900,
            darker: LIGHT_PALETTE_TEAL_900
        },
        contrastIconText: {
            lighter: LIGHT_PALETTE_TEAL_500,
            light: LIGHT_PALETTE_TEAL_600,
            main: LIGHT_PALETTE_TEAL_700,
            dark: LIGHT_PALETTE_TEAL_800,
            darker: LIGHT_PALETTE_TEAL_900
        }
    },
    100: {
        color: LIGHT_PALETTE_TEAL_100,
        contrastText: {
            lighter: LIGHT_PALETTE_TEAL_700,
            light: LIGHT_PALETTE_TEAL_800,
            main: LIGHT_PALETTE_TEAL_900,
            dark: LIGHT_PALETTE_TEAL_900,
            darker: LIGHT_PALETTE_TEAL_900
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    200: {
        color: LIGHT_PALETTE_TEAL_200,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    300: {
        color: LIGHT_PALETTE_TEAL_300,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    400: {
        color: LIGHT_PALETTE_TEAL_400,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    500: {
        color: LIGHT_PALETTE_TEAL_500,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    600: {
        color: LIGHT_PALETTE_TEAL_600,
        contrastText: {
            lighter: LIGHT_PALETTE_TEAL_100,
            light: LIGHT_PALETTE_TEAL_200,
            main: LIGHT_PALETTE_TEAL_300,
            dark: LIGHT_PALETTE_TEAL_400,
            darker: LIGHT_PALETTE_TEAL_500
        },
        contrastIconText: {
            lighter: LIGHT_PALETTE_TEAL_50,
            light: LIGHT_PALETTE_TEAL_100,
            main: LIGHT_PALETTE_TEAL_200,
            dark: LIGHT_PALETTE_TEAL_300,
            darker: LIGHT_PALETTE_TEAL_400
        }
    },
    700: {
        color: LIGHT_PALETTE_TEAL_700,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    800: {
        color: LIGHT_PALETTE_TEAL_800,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
    900: {
        color: LIGHT_PALETTE_TEAL_900,
        contrastText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        },
        contrastIconText: {
            lighter: WHITE,
            light: WHITE,
            main: WHITE,
            dark: WHITE,
            darker: WHITE
        }
    },
};
const LIGHT_PALETTE: LightUIPalette = {
    ...BASE_PALETTE,
    type: 'light',
    neutral: LIGHT_PALETTE_NEUTRAL,
    lightBlue: LIGHT_PALETTE_LIGHT_BLUE,
    cyan: LIGHT_PALETTE_CYAN,
    pink: LIGHT_PALETTE_PINK,
    red: LIGHT_PALETTE_RED,
    yellow: LIGHT_PALETTE_YELLOW,
    teal: LIGHT_PALETTE_TEAL,

    brands: SOCIAL_MEDIA_BRANDS,
    background: { default: LIGHT_PALETTE_NEUTRAL[50], paper: LIGHT_PALETTE_NEUTRAL[50] },
    text: {
        color: {
            lighter: LIGHT_PALETTE_NEUTRAL[500].color,
            light: LIGHT_PALETTE_NEUTRAL[700].color,
            main: LIGHT_PALETTE_NEUTRAL[900].color,
            dark: LIGHT_PALETTE_NEUTRAL[900].color,
            darker: LIGHT_PALETTE_NEUTRAL[900].color
        },
        icon: {
            lighter: LIGHT_PALETTE_NEUTRAL[300].color,
            light: LIGHT_PALETTE_NEUTRAL[500].color,
            main: LIGHT_PALETTE_NEUTRAL[700].color,
            dark: LIGHT_PALETTE_NEUTRAL[800].color,
            darker: LIGHT_PALETTE_NEUTRAL[800].color
        },
        selection: LIGHT_PALETTE_NEUTRAL[200]
    },
    links: {
        default: LIGHT_PALETTE_TEAL[600],
        hover: LIGHT_PALETTE_TEAL[400].color
    },
    divider: { main: LIGHT_PALETTE_NEUTRAL[200].color, light: LIGHT_PALETTE_NEUTRAL[100].color },
    code: {
        inline: LIGHT_PALETTE_NEUTRAL[100],
        block: {
            insetShadowColor: 'hsla(230, 23%, 27%, 1)',
            lineHighlight: {
                background: LIGHT_PALETTE_NEUTRAL[800],
                accent: LIGHT_PALETTE_NEUTRAL[300]
            }
        }
    },
    quote: {
        border: LIGHT_PALETTE_NEUTRAL[600],
        text: LIGHT_PALETTE_NEUTRAL[600]
    },
    tooltip: {
        border: LIGHT_PALETTE_NEUTRAL[100],
        background: LIGHT_PALETTE_NEUTRAL[50]
    },
    header: {
        default: LIGHT_PALETTE_NEUTRAL[50], // Same as background.default
        home: {
            main: LIGHT_PALETTE_NEUTRAL[900].color,
            hover: LIGHT_PALETTE_PINK[400].color
        },
        nav: {
            hover: LIGHT_PALETTE_TEAL[400].color
        }
    },
    footer: {
        default: LIGHT_PALETTE_NEUTRAL[900],
        divider: LIGHT_PALETTE_NEUTRAL[600].color,
        links: {
            hover: LIGHT_PALETTE_TEAL[400].color // TODO: Potentially unused
        }
    },
    avatar: {
        insetBoxShadow: 'hsla(0, 0%, 0%, 0.1)', // TODO: Make a color variable instead?
        icon: {
            background: LIGHT_PALETTE_NEUTRAL[200].color,
            color: LIGHT_PALETTE_NEUTRAL[500].color
        }
    },
    pages: {
        home: {
            greeting: LIGHT_PALETTE_PINK[300].color
        },
        blog: {
            headingLink: LIGHT_PALETTE_TEAL[700] // Same as regular link
        },
        projects: {
            previewCard: {
                stack: LIGHT_PALETTE_NEUTRAL[600]
            }
        }
    },
    card: {
        labelArea: LIGHT_PALETTE_NEUTRAL[100]
    },
    tabs: {
        default: LIGHT_PALETTE_NEUTRAL[500].color,
        disabled: LIGHT_PALETTE_NEUTRAL[300].color,
        selected: LIGHT_PALETTE_NEUTRAL[900].color,
        hover: LIGHT_PALETTE_NEUTRAL[700].color,
        indicator: LIGHT_PALETTE_PINK[200].color
    },
};
const LIGHT_THEME_PALETTE: Palette = createPalette({
    custom: LIGHT_PALETTE,
    // Hack to get primary and secondary colors reset. Might cause me to miss some odd colorations of things?
    primary: {
        light: LIGHT_PALETTE.text.color.light,
        main: LIGHT_PALETTE.text.color.main,
        dark: LIGHT_PALETTE.text.color.dark,
        contrastText: LIGHT_PALETTE.text.color.main
    },
    secondary: {
        light: LIGHT_PALETTE.text.color.light,
        main: LIGHT_PALETTE.text.color.main,
        dark: LIGHT_PALETTE.text.color.dark,
        contrastText: LIGHT_PALETTE.text.color.main
    },
    text: {
        primary: LIGHT_PALETTE.text.color.main,
        secondary: LIGHT_PALETTE.text.color.light
    },
    background: {
        default: LIGHT_PALETTE.background.default.color,
        paper: LIGHT_PALETTE.background.paper.color
    },
    divider: LIGHT_PALETTE.divider.main,
    action: {
        hoverOpacity: 0.1 // Hover and ripple for buttons use this with textPrimary
    }
});

export const LIGHT_THEME: Theme = createMuiTheme({
    ...BASE_MUI_THEME,
    palette: LIGHT_THEME_PALETTE,
    overrides: {
        MuiDivider: {
            root: {
                width: '100%',
                border: 'none',
                height: '2px',
                marginBottom: `calc(${BASELINE.blockMarginBottom}px - 2px)`, // Adjust margin for height of <hr>
                backgroundColor: LIGHT_PALETTE.divider.main
            },
            vertical: {
                marginBottom: 0,
            },
            light: {
                backgroundColor: LIGHT_PALETTE.divider.light
            }
        },
        MuiTypography: {
            paragraph: {
                marginBottom: BASELINE.blockMarginBottom
            },
        },
        MuiSvgIcon: {
            root: {
                ...TYPOGRAPHY.iconSizing(2)
            },
            fontSizeSmall: { ...TYPOGRAPHY.iconSizing(1) },
            fontSizeLarge: { ...TYPOGRAPHY.iconSizing(3) },
        },
        MuiTooltip: {
            tooltip: {
                ...TYPOGRAPHY.body1,
                boxShadow: SHADOWS[6],
                border: `1px solid ${LIGHT_PALETTE.tooltip.border.color}`,
                backgroundColor: LIGHT_PALETTE.tooltip.background.color,
                color: LIGHT_PALETTE.tooltip.background.color,
                padding: SPACING(1, 2)
            },
            touch: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit'
            }
        },
        MuiCardActionArea: {
            focusHighlight: {
                color: LIGHT_PALETTE_NEUTRAL[300].color
            }
        },
        MuiTouchRipple: {
            child: {
                color: LIGHT_PALETTE_NEUTRAL[200].color
            }
        },
        MuiButton: {
            root: {
                ...TYPOGRAPHY.sizing(4),
                padding: SPACING(2, 3),
                // '&:hover': {
                //     // backgroundColor: LIGHT_PALETTE.text.lighter
                // }
            },
            text: {
                padding: SPACING(1, 2)
            },
            outlined: {
                border: '1px solid currentColor',
                padding: `${SPACING(2) - 1}px ${SPACING(3)}px` // Account for border height
            },
            sizeSmall: {
                ...TYPOGRAPHY.sizing(3),
                padding: SPACING(1, 2)
            },
            sizeLarge: {
                ...TYPOGRAPHY.sizing(5),
                padding: SPACING(2, 4),
            }
        },
        MuiCardContent: {
            root: {
                // padding: SPACING(2, 4, 2, 5),
                padding: SPACING(5, 6),
                '&:last-child': { paddingBottom: 'inherit' }
            }
        },
        MuiAppBar: {
            colorDefault: {
                color: LIGHT_PALETTE.header.default.contrastText.main,
                backgroundColor: LIGHT_PALETTE.header.default.color
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'initial'
            }
        },
        MuiListItemText: {
            root: {
                marginTop: 0,
                marginBottom: 0,
                marginLeft: SPACING(3)
            },
            multiline: {
                // marginTop: SPACING(2),
                // marginBottom: SPACING(2),
                marginTop: 0,
                marginBottom: 0,
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: LIGHT_PALETTE.tabs.indicator,
                transition: transitions.create('top')
            }
        },
        MuiTab: {
            root: {
                padding: SPACING(2, 3),
                [BREAKPOINTS.up('sm')]: {
                    fontSize: TYPOGRAPHY.button.fontSize,
                    minWidth: 0,
                    padding: SPACING(2, 2),
                },
                '&$disabled': { color: LIGHT_PALETTE.tabs.disabled },
                '&$selected': { color: LIGHT_PALETTE.tabs.selected },
            },
            textColorInherit: {
                transition: transitions.create('color', { duration: transitions.duration.shortest }),
                color: LIGHT_PALETTE.tabs.default,
                '&:hover': {
                    color: LIGHT_PALETTE.tabs.hover
                },
                opacity: 1,
                '&$selected': {
                    opacity: 1,
                    color: LIGHT_PALETTE.tabs.selected
                },
                '&$disabled': {
                    opacity: 1,
                    color: LIGHT_PALETTE.tabs.disabled
                },
            },
        },
        MuiList: {
            root: {
                '& &': BASELINE.list
            }
        }
    }
});

/**
 * Dark Theme
 */

// TODO
const DARK_PALETTE: DarkUIPalette = {
    ...BASE_PALETTE,
    type: 'dark',

    brands: SOCIAL_MEDIA_BRANDS,
};
const DARK_THEME_PALETTE: Palette = createPalette({ custom: DARK_PALETTE });

export const DARK_THEME: Theme = createMuiTheme({
    ...BASE_MUI_THEME,
    palette: DARK_THEME_PALETTE
});
