import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../../lib/types/utils';
import { UseStyles } from '../../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { Baseline } from '../Baseline';

type ClassKey = 'root';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        minHeight: '100vh',
        width: '100%'
    }
}));

export function Root(props: TReactFCP): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        <Baseline>
            <div className={styles.root}>{props.children}</div>
        </Baseline>
    );
}
