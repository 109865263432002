import * as React from 'react';
import clsx from 'clsx';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { Link as MuiLink, Theme, makeStyles } from '@material-ui/core';
import { LinkProps as MuiLinkProps } from '@material-ui/core/Link';
import { TReactFCP, TReactFCR, TReactFREC, AnchorOnClickEvent } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { AnchorProps, redirectTo } from '@reach/router';
import { CreateCSSProperties } from '@material-ui/styles';

type StyleProps = { disableColor: boolean; inheritColor: boolean; fullWidth: boolean; };

type ClassKey = 'root' | 'disableUnderline' | 'bold' | 'button';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    root(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = { color: 'inherit' };
        if (!props.disableColor) {
            if (!props.inheritColor) {
                style.color = theme.palette.custom.links.default.color;
            }
            style['&:hover, &:active'] = { color: theme.palette.custom.links.hover };
        }
        return style;
    },
    disableUnderline: { boxShadow: 'none' },
    bold: { fontWeight: theme.typography.fontWeightBold },
    button(props: StyleProps) {
        const style: CreateCSSProperties<StyleProps> = {};
        if (props.fullWidth) {
            style.width = '100%';
        }
        return style;
    }
}));

export type TLinkP<TState = {}> = Omit<AnchorProps, 'ref'> & {
    className?: string;
    variant: 'internal' | 'internalTab' | 'external';
    to: string;
    bold?: boolean;
    disableUnderline?: boolean; // | 'hover' | 'always'; // Mirrors MUI Link Props
    disableColor?: boolean;
    inheritColor?: boolean;
    button?: boolean;
    fullWidth?: boolean;
    // TODO: Enable 'ref' again. I think I just need to wrap it in a forward ref?
    InternalLinkProps?: Omit<GatsbyLinkProps<TState>, 'to' | 'activeStyle' | 'activeClassName' | 'ref'>;
    ExternalLinkProps?: Omit<MuiLinkProps, 'href' | 'underline' | 'classes' | 'className'>;
};

export const Link: TReactFREC<TLinkP, HTMLAnchorElement> = React.forwardRef(
    (props: TReactFCP<TLinkP>, ref: React.Ref<HTMLAnchorElement>): TReactFCR => {
        const {
            className, variant, to, bold = false, disableUnderline = false, disableColor = false, inheritColor = false,
            button = false, fullWidth = false, InternalLinkProps = {}, ExternalLinkProps = {}, ...anchorProps
        }: TLinkP = props;
        const externalProps: MuiLinkProps = {};
        const styles: UseStyles<ClassKey, StyleProps> = useStyles({ disableColor, inheritColor, fullWidth });
        const linkStyle: string = clsx(
            styles.root,
            {
                [styles.disableUnderline]: disableUnderline,
                [styles.bold]: bold,
                [styles.button]: button
            },
            className
        );
        // Tracks clicks on external links.
        // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/src/index.js
        function handleExternalClick(e: AnchorOnClickEvent) {
            const redirect = !(
                e.button !== 0 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.defaultPrevented
            );
            if (window.gtag) {
                window.gtag('event', 'click', {
                    event_category: 'outbound',
                    event_label: to,
                    transport_type: redirect ? 'beacon' : '',
                    event_callback: () => { if (redirect) redirectTo(to); }
                });
            }
        }
        switch (variant) {
            case 'internal':
                return (
                    <GatsbyLink
                        className={linkStyle}
                        to={to}
                        innerRef={ref}
                        {...InternalLinkProps}
                        {...anchorProps}
                    >
                        {props.children}
                    </GatsbyLink>
                );
            case 'external':
                externalProps.onClick = handleExternalClick;
            // eslint-disable-next-line no-fallthrough
            case 'internalTab':
            default:
                const { color, ...muiAnchorProps } = anchorProps;
                return (
                    <MuiLink
                        innerRef={ref as any} // Hack for bad typing on Reach's part
                        target='_blank'
                        rel='noopener noreferrer'
                        className={linkStyle}
                        underline='none' // Controlled by us. Disable Mui's styling
                        href={to}
                        {...externalProps}
                        {...ExternalLinkProps}
                        {...muiAnchorProps}
                    >
                        {props.children}
                    </MuiLink>
                );
        }
    }
);