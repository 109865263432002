import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '5bf37229-7b68-410b-974e-a091fcb09f11',
    clientToken: 'pub43a033641dff9817a749df75d60ebe90',
    site: 'datadoghq.com',
    service:'jrichter.io',
    env:'prod',
    version: '2.9.2',
    sampleRate: 100,
    premiumSampleRate: 100,
    telemetrySampleRate: 0,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel:'allow',
    allowedTracingOrigins: [/https:\/\/.*\.jrichter\.io/],
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true
});

datadogRum.startSessionReplayRecording();
