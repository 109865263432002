import { SPACING } from './spacing';
import { CSSProperties } from '@material-ui/styles';

export type Baseline = {
    blockMarginBottom: number;
    prePadding: number;
    list: CSSProperties;
    nestedList: CSSProperties;
    listItem: CSSProperties;
};

const BLOCK_MARGIN_BOTTOM: number = SPACING(5);
const NESTED_LIST: CSSProperties = {
    marginBottom: SPACING(1),
    marginTop: SPACING(1)
};

export const BASELINE: Baseline = {
    blockMarginBottom: BLOCK_MARGIN_BOTTOM,
    prePadding: SPACING(4),
    list: {
        listStylePosition: 'outside',
        listStyleImage: 'none',
        marginLeft: SPACING(6),
        marginBottom: BLOCK_MARGIN_BOTTOM
    },
    nestedList: NESTED_LIST,
    listItem: {
        marginBottom: SPACING(3),
        '&:last-child': { marginBottom: 0 },
        '& > ol, & > ul': NESTED_LIST,
        '& > p': { marginBottom: SPACING(1) }
    },
}
