import * as React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { StoresProvider } from '../../lib/stores/provider';
import { STORES, IStores } from '../../lib/stores/root';
import { TReactFCR, TReactFCP } from '../../lib/types/utils';
import { LayoutProvider } from '../../lib/stores/layout';

/**
 * See src/config/muiStylesProviderProps.js for details on why this is commented out.
 */
// import { StylesProvider } from '@material-ui/styles';
// import STYLES_PROVIDER_PROPS from '../../config/muiStylesProviderProps';

export interface IProvidersP { }

export function Providers(props: TReactFCP<IProvidersP>): TReactFCR {
    const { ui }: IStores = STORES;
    return (
        // {/* <StylesProvider {...STYLES_PROVIDER_PROPS}> */ } injected by gatsby-plugin-material-ui
        <MuiThemeProvider theme={ui.theme}>
            <StoresProvider value={STORES}>
                <LayoutProvider>
                    {props.children}
                </LayoutProvider>
            </StoresProvider>
        </MuiThemeProvider>
        // {/* </StylesProvider> */ }
    );
}
