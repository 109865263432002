import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../../../lib/types/utils';
import { NavDrawer, TNavDrawerP } from './NavDrawer';
import { TNavListP, NavList } from './NavList';
import { NavLinkConfig } from './NavLink';

export type TNavP = {
    variant: 'drawer' | 'list';
    config: NavLinkConfig[];
    DrawerProps?: TNavDrawerP;
    ListProps?: TNavListP;
}

export function Nav(props: TReactFCP<TNavP>): TReactFCR {
    switch (props.variant) {
        case 'drawer':
            return <NavDrawer config={props.config} {...props.DrawerProps} />;
        case 'list':
        default:
            return <NavList config={props.config} {...props.ListProps} />;
    }
};
