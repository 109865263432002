import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../../../../lib/types/utils';
import { observer } from 'mobx-react';
import { Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../../../../lib/types/mui';
import { TNavLayoutState, ILayoutStore, useLayoutStore } from '../../../../lib/stores/layout';
import { NavLinkConfig, NavLink, TNavLinkP } from './NavLink';

type StyleProps = TNavLayoutState;

type ClassKeys = 'nav' | 'margin';
const useStyles = makeStyles<Theme, ClassKeys>((theme: Theme) => ({
    nav: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    margin: { marginBottom: theme.baseline.blockMarginBottom }
}));

export type TNavListP = {
    config: NavLinkConfig[];
    NavLinkProps?: Omit<TNavLinkP, 'variant' | 'config'>;
    enableMargin?: boolean;
};

export const NavList: React.FC<TNavListP> = observer((props: TReactFCP<TNavListP>): TReactFCR => {
    const store: ILayoutStore = useLayoutStore();
    const styles: UseStyles<ClassKeys, StyleProps> = useStyles(store.currentNavLayout);
    const { enableMargin, NavLinkProps, config }: TNavListP = props;
    const links: React.ReactNode[] = config.map((c: NavLinkConfig) => (
        <NavLink key={c.path} variant='list' config={c} {...NavLinkProps} />
    ));
    const navStyles: string = clsx(styles.nav, { [styles.margin]: enableMargin });
    return <nav className={navStyles}>{links}</nav>;
});
