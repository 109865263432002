import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../../../../lib/types/utils';
import { ListItem, ListItemIcon, ListItemText, Theme, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { UseStyles } from '../../../../lib/types/mui';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { Typography } from '../../../Typography';

type StyleProps = Pick<TNavLinkP, 'disableIcon' | 'disableActive'>;

type ClassKey = 'list' | 'link' | 'icon' | 'text' | 'textMargin' | 'active';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    list: {
        flex: '1 1 auto',
        display: 'inline-flex',
        alignItems: 'center',
        margin: theme.spacing(0, 1),
    },
    link: {
        boxShadow: 'none',
        padding: theme.spacing(2, 3),
        color: theme.palette.custom.background.default.contrastText.lighter,
        '&:active, &:hover': {
            color: theme.palette.custom.header.nav.hover
        }
    },
    icon: { ...theme.typography.iconSizing(2), color: 'inherit' },
    text: { ...theme.typography.sizing(4) },
    textMargin(props: StyleProps) {
        return { marginLeft: props.disableIcon ? 0 : theme.spacing(2) };
    },
    active(props: StyleProps) {
        if (props.disableActive) return {};
        return {
            color: theme.palette.custom.header.nav.hover,
        };
    }
}));

export type NavLinkConfig = { label: string, path: string, icon: React.ElementType<{ className?: string; }>; };

export type TNavLinkP = {
    classes?: Partial<ClassNameMap<Exclude<ClassKey, 'inline'>>>;
    variant: 'list' | 'drawer';
    disableIcon?: boolean;
    disableActive?: boolean;
    config: NavLinkConfig;
};

export function NavLink(props: TReactFCP<TNavLinkP>): TReactFCR {
    const { variant, config, disableIcon = false, disableActive = false, classes = {} }: TNavLinkP = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ disableIcon, disableActive });
    const { label, path, icon: Icon }: NavLinkConfig = config;
    const linkStyles: string = clsx(styles.link, classes.link);
    const activeLinkStyles: string = clsx(styles.active, classes.active);
    const iconStyles: string = clsx(styles.icon, classes.icon);
    const typographyStyles: string = clsx(styles.text, classes.text);
    if (variant !== 'drawer') {
        return (
            <Link
                className={clsx(styles.list, linkStyles)}
                activeClassName={activeLinkStyles}
                partiallyActive to={path}
            >
                {!disableIcon && <Icon className={iconStyles} />}
                <Typography className={clsx(styles.textMargin, typographyStyles)}>{label}</Typography>
            </Link>
        );
    }
    return (
        <li>
            <ListItem
                component={Link}
                to={path}
                className={linkStyles}
                activeClassName={activeLinkStyles}
                partiallyActive
            >
                {!disableIcon && <ListItemIcon className={iconStyles}><Icon /></ListItemIcon>}
                <ListItemText classes={{ primary: typographyStyles }} primary={label} />
            </ListItem>
        </li>
    );
}
