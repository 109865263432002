import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import {
    InstagramIcon, YoutubeIcon, SpotifyIcon, GithubIcon, GitlabIcon, LinkedInIcon, TwitterIcon, FacebookIcon, AtIcon,
    KeybaseIcon, RssIcon, RedditIcon, TumblrIcon, HeartbeatIcon
} from './FAIcons';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { ClassNameMap, CreateCSSProperties } from '@material-ui/styles/withStyles';
import { IconSizes } from '../lib/theme/typography';
import { Link, TLinkP } from './Link';
import { TSocialVariants } from '../lib/types/social';
import { useStaticQuery, graphql } from 'gatsby';
import {
    GQLSiteSiteMetadataSocial, GQLSocialLinkSiteMetadataQuery
} from '../lib/types/graphql/__generated__/gatsby.gql';

type StylesProps = {
    iconSize: IconSizes;
    variant: TSocialLinkP['variant'];
    disableBrandColor: TSocialLinkP['disableBrandColor'];
    enableText: TSocialLinkP['enableText'];
};

type ClassKey = 'link' | 'icon';
const useStyles = makeStyles<Theme, StylesProps, ClassKey>((theme: Theme) => ({
    link(props: StylesProps) {
        let hoverColor: string | undefined = undefined;
        const styles: CreateCSSProperties<StylesProps> = { display: 'inline' };
        if (!props.disableBrandColor) {
            switch (props.variant) {
                case 'linkedin':
                    hoverColor = theme.palette.custom.brands.linkedIn;
                    break;
                case 'twitter':
                    hoverColor = theme.palette.custom.brands.twitter;
                    break;
                case 'facebook':
                    hoverColor = theme.palette.custom.brands.facebook;
                    break;
                case 'instagram':
                    hoverColor = theme.palette.custom.brands.instagram;
                    break;
                case 'youtube':
                    hoverColor = theme.palette.custom.brands.youtube;
                    break;
                case 'spotify':
                    hoverColor = theme.palette.custom.brands.spotify;
                    break;
                case 'github':
                    hoverColor = theme.palette.custom.brands.github;
                    break;
                case 'gitlab':
                    hoverColor = theme.palette.custom.brands.gitlab;
                    break;
                case 'keybase':
                    hoverColor = theme.palette.custom.brands.keybase;
                    break;
                case 'tumblr':
                    hoverColor = theme.palette.custom.brands.tumblr;
                    break;
                case 'fitbit':
                    hoverColor = theme.palette.custom.brands.fitbit;
                    break;
                case 'reddit':
                    hoverColor = theme.palette.custom.brands.reddit;
                    break;
                case 'jawbone':
                    hoverColor = theme.palette.custom.brands.jawbone;
                    break;
                case 'runkeeper':
                    hoverColor = theme.palette.custom.brands.runkeeper;
                    break;
                default:
                    hoverColor = theme.palette.custom.links.hover;
                    break;
            }
            styles['&:hover, &:active'] = { color: hoverColor };
        }
        return styles;
    },
    icon(props: StylesProps) {
        return {
            ...theme.typography.iconSizing(props.iconSize),
            marginRight: props.enableText ? theme.spacing(2) : undefined,
            verticalAlign: 'middle',
            color: theme.palette.custom.text.color.light,
            '&:hover, &:active': { color: 'currentColor' }
        };
    },
}));

export type TSocialLinkP = Pick<TLinkP, 'disableColor' | 'ExternalLinkProps' | 'bold'> & {
    classes?: Partial<ClassNameMap<ClassKey>>;
    variant: TSocialVariants;
    handle?: string;
    homepage?: boolean;
    iconSize?: IconSizes;
    enableUnderline?: boolean;
    enableText?: boolean;
    disableIcon?: boolean;
    disableBrandColor?: boolean;
};

export function SocialLink(props: TReactFCP<TSocialLinkP>): TReactFCR {
    const { site }: GQLSocialLinkSiteMetadataQuery = useStaticQuery(graphql`
        query SocialLinkSiteMetadata {
            site {
                siteMetadata {
                    social {
                        email
                        linkedin
                        twitter
                        facebook
                        instagram
                        youtube
                        spotify
                        github
                        gitlab
                        keybase
                        tumblr
                        fitbit
                        reddit
                    }
                }
            }
        }
    `);
    const {
        children, classes, variant, handle, enableText = false, disableIcon = false, iconSize = 2,
        enableUnderline = false, disableBrandColor = false, homepage = false, ...linkProps
    }: TReactFCP<TSocialLinkP> = props;
    const styles: UseStyles<ClassKey, StylesProps> = useStyles({ iconSize, variant, disableBrandColor, enableText });
    const linkStyles: string = clsx(styles.link, classes && classes.link);
    const iconStyles: string = clsx(styles.icon, classes && classes.icon);
    let to: string = '';
    let social: GQLSiteSiteMetadataSocial = {};
    if (handle === undefined) {
        social = site!.siteMetadata!.social!;
    }
    switch (variant) {
        case 'linkedin':
            to = homepage ?
                'https://linkedin.com' :
                `https://www.linkedin.com/in/${handle ?? social!.linkedin}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <LinkedInIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'LinkedIn')}
                </Link>
            );
        case 'twitter':
            to = homepage ?
                'https://twitter.com' :
                `https://twitter.com/${handle ?? social!.twitter}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <TwitterIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Twitter')}
                </Link>
            );
        case 'facebook':
            to = homepage ?
                'https://www.facebook.com' :
                `https://www.facebook.com/${handle ?? social!.facebook}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <FacebookIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Facebook')}
                </Link>
            );
        case 'instagram':
            to = homepage ?
                'https://www.instagram.com' :
                `https://www.instagram.com/${handle ?? social!.instagram}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <InstagramIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Instagram')}
                </Link>
            );
        case 'youtube':
            to = homepage ?
                'https://www.youtube.com' :
                `https://www.youtube.com/channel/${handle ?? social!.youtube}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <YoutubeIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Youtube')}
                </Link>
            );
        case 'spotify':
            to = homepage ?
                'https://spotify.com' :
                `https://open.spotify.com/user/${handle ?? social!.spotify}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <SpotifyIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Spotify')}
                </Link>
            );
        case 'github':
            to = homepage ?
                'https://github.com' :
                `https://github.com/${handle ?? social!.github}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <GithubIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Github')}
                </Link>
            );
        case 'gitlab':
            to = homepage ?
                'https://gitlab.com' :
                `https://gitlab.com/${handle ?? social!.gitlab}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <GitlabIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Gitlab')}
                </Link>
            );
        case 'keybase':
            to = homepage ?
                'https://keybase.io' :
                `https://keybase.io/${handle ?? social!.keybase}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <KeybaseIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Keybase')}
                </Link>
            );
        case 'fitbit':
            to = homepage ?
                'https://www.fitbit.com/' :
                `https://www.fitbit.com/user/${handle ?? social!.fitbit}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <HeartbeatIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Fitbit')}
                </Link>
            );
        case 'tumblr':
            to = homepage ?
                'https://tumblr.com/' :
                `https://${handle ?? social!.tumblr}.tumblr.com/`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <TumblrIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Tumblr')}
                </Link>
            );
        case 'reddit':
            to = homepage ?
                'https://reddit.com/' :
                `https://www.reddit.com/user/${handle ?? social!.reddit}`;
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <RedditIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Reddit')}
                </Link>
            );
        case 'jawbone':
            // This product has been discontinued
            to = 'https://jawbone.com/';
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {/* {!disableIcon && <JawboneIcon className={iconStyles} fixedWidth />} */}
                    {children || (enableText && 'Jawbone')}
                </Link>
            );
        case 'runkeeper':
            // This product has been largely discontinued
            to = 'https://runkeeper.com/';
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={to}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {/* {!disableIcon && <RunkeeperIcon className={iconStyles} fixedWidth />} */}
                    {children || (enableText && 'Runkeeeper')}
                </Link>
            );
        case 'email':
            return (
                <Link
                    variant='external'
                    className={linkStyles}
                    to={`mailto:${handle ?? social!.email}`}
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <AtIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Email')}
                </Link>
            );
        case 'blogRss':
            return (
                <Link
                    variant='internal'
                    className={linkStyles}
                    to='/blog/rss.xml'
                    disableUnderline={!enableUnderline}
                    {...linkProps}
                >
                    {!disableIcon && <RssIcon className={iconStyles} fixedWidth />}
                    {children || (enableText && 'Blog RSS Feed')}
                </Link>
            );
        default:
            return null;
    }
}
