import * as React from 'react';
import clsx from 'clsx';
import { Divider as MuiDivider, Theme, makeStyles } from '@material-ui/core';
import { DividerProps } from '@material-ui/core/Divider';
import { UseStyles } from '../../lib/types/mui';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';

type StyleProps = { isVertical: boolean; };
type ClassKey = 'thin' | 'regular' | 'thick';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    thin({ isVertical }: StyleProps) {
        if (isVertical) {
            return { width: '1px', marginBottom: 0 };
        }
        return {
            height: '1px',
            marginBottom: `calc(${theme.baseline.blockMarginBottom}px - 1px)`
        };
    },
    regular({ isVertical }: StyleProps) {
        if (isVertical) {
            return { width: '2px', marginBottom: 0 };
        }
        return {
            height: '2px',
            marginBottom: `calc(${theme.baseline.blockMarginBottom}px - 2px)`
        };
    },
    thick({ isVertical }: StyleProps) {
        if (isVertical) {
            return { width: '3px', marginBottom: 0 };
        }
        return {
            height: '3px',
            marginBottom: `calc(${theme.baseline.blockMarginBottom}px - 3px)`
        };
    },
}));

export type TDividerP = DividerProps & {
    size?: 'thin' | 'regular' | 'thick';
};

export function Divider(props: TReactFCP<TDividerP>): TReactFCR {
    const { className, ...muiDividerProps }: TDividerP = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({
        isVertical: muiDividerProps.orientation === 'vertical'
    });
    const rootStyles: string = clsx(
        {
            [styles.thin]: props.size === 'thin',
            [styles.regular]: props.size === 'regular',
            [styles.thick]: props.size === 'thick',
        },
        className
    );
    return <MuiDivider className={rootStyles} {...muiDividerProps} />;
}
