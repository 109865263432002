import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Theme, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../../../lib/types/utils';
import { GQLFooterSiteMetadataQuery } from '../../../lib/types/graphql/__generated__/gatsby.gql';
import { UseStyles } from '../../../lib/types/mui';
import { Typography } from '../../Typography';
import { NavList, TNavListP } from './Nav/NavList';
import { Divider } from '../Divider';
import { SocialList } from '../../SocialList';
import { FOOTER_NAV_LINKS } from '../../../config/nav';

type ClassKey =
    'footer' | 'footerContainer' | 'socialList' | 'divider' | 'navLink' | 'navText' | 'copyright' | 'socialIcon';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    footer: {
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.custom.footer.default.color,
        color: theme.palette.custom.footer.default.contrastText.darker,
    },
    footerContainer: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: theme.breakpoints.width('md'),
        padding: theme.spacing(5, 2, 2, 2)
    },
    socialList: { marginBottom: theme.spacing(2) },
    divider: { backgroundColor: theme.palette.custom.footer.divider },
    navLink: {
        color: 'inherit',
        paddingTop: 0,
        paddingBottom: 0,
    },
    navText: { ...theme.typography.overline },
    copyright: { color: theme.palette.custom.footer.default.contrastText.darker },
    socialIcon: { color: theme.palette.custom.footer.default.contrastIconText.darker }
}));

export type TFooterP = {};

export function Footer(props: TReactFCP): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { site }: GQLFooterSiteMetadataQuery = useStaticQuery(graphql`
        query FooterSiteMetadata {
            site {
                siteMetadata {
                    siteName
                }
            }
        }
    `);
    const navLinkProps: TNavListP['NavLinkProps'] = {
        disableIcon: true,
        disableActive: true,
        classes: { link: styles.navLink, text: styles.navText }
    };
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <NavList config={FOOTER_NAV_LINKS} NavLinkProps={navLinkProps} enableMargin />
                <Divider className={styles.divider} variant='middle' size='thin' />
                <SocialList
                    className={styles.socialList}
                    inline
                    SocialLinkProps={{
                        classes: { icon: styles.socialIcon },
                        iconSize: 2, disableColor: true, disableBrandColor: true
                    }}
                />
                <Typography className={styles.copyright} display='block' variant='caption'>
                    {`© ${new Date().getUTCFullYear()} ${site!.siteMetadata!.siteName}`}
                </Typography>
            </div>
        </footer>
    );
}
