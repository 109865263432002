import { Typography } from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/styles';

export type FontTypes = 'heading' | 'body' | 'mono';
export type TypeSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
// type TypeScale =   12 | 14 | 16 | 18 | 20 | 24 | 30 | 36 | 48 | 60 | 72;
export type TypeSize = Required<Pick<
    CSSProperties,
    "fontSize" | "fontFeatureSettings" | 'letterSpacing' | 'lineHeight' | 'fontFamily' | 'fontWeight' | 'fontStyle'
>>;
export type TypeSizing = Record<TypeSizes, TypeSize>;
export type FontFeatureSettings = {
    body: Required<CSSProperties['fontFeatureSettings']>;
    heading: Required<CSSProperties['fontFeatureSettings']>;
    mono: Required<CSSProperties['fontFeatureSettings']>;
};

export type IconSizes = 1 | 2 | 3 | 4;
// export type IconSize = Required<
//     Pick<CSSProperties, "fontSize" | 'letterSpacing' | 'lineHeight' | 'fontFamily' | 'fontWeight' | 'fontStyle'>
// >;
export type IconSizing = Record<IconSizes, TypeSize>;

function generateTypeSizing(
    family: string, featureSettings: string, sizePx: number, weight: number, style: string, letterSpacingEm?: number,
    lineHeight?: number
): TypeSize {
    return {
        fontFamily: family,
        fontFeatureSettings: featureSettings,
        fontSize: `${sizePx}px`,
        fontWeight: weight,
        fontStyle: style,
        // For icon support
        letterSpacing: letterSpacingEm !== undefined ? `${letterSpacingEm}em` : 'inherit',
        lineHeight: lineHeight !== undefined ? lineHeight : 'inherit',
    };
}


const HTML_FONT_SIZE: number = 16;
const FONT_SIZE: number = 16;

const BODY_FONT_FAMILY: string = `'Inter','Noto Sans','Open Sans',sans-serif`;
// https://rsms.me/inter/lab/?antialias=default&feat-ss02=1&feat-cv11=1&feat-dlig=1
// `cv11` (single story a), `ss02` (disambiguation), `dlig` (discretionary ligatures - interrobangs)
const BODY_FONT_FEATURE_SETTINGS: string = `"kern", "liga", "dlig", "clig", "calt", "ss02", "cv11"`;
const BODY_TYPE_SIZING: TypeSizing = {
    1: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FEATURE_SETTINGS, 12, 400, 'normal', 0.03, 2.00),
    2: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 14, 400, 'normal', 0.02, 1.83),
    3: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 16, 400, 'normal', 0.01, 1.79),
    4: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 18, 400, 'normal', 0.00, 1.70),
    5: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 20, 400, 'normal', -0.01, 1.63),
    6: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 24, 400, 'normal', -0.02, 1.53),
    7: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 30, 400, 'normal', -0.04, 1.41),
    8: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 36, 400, 'normal', -0.04, 1.32),
    9: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 48, 400, 'normal', -0.05, 1.20),
    10: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 60, 400, 'normal', -0.06, 1.12),
    11: generateTypeSizing(BODY_FONT_FAMILY, BODY_FONT_FAMILY, 72, 400, 'normal', -0.07, 1.05),
};
const HEADING_FONT_FAMILY: string = `'Exo',${BODY_FONT_FAMILY}`;
const HEADING_FONT_FEATURE_SETTINGS: string = `"kern", "liga", "clig", "calt", "ss02", "cv11"`;
const HEADING_TYPE_SIZING: TypeSizing = {
    1: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 12, 600, 'normal', 0.05, 2.00),
    2: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 14, 600, 'normal', 0.03, 1.80),
    3: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 16, 600, 'normal', 0.02, 1.75),
    4: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 18, 600, 'normal', 0.00, 1.65),
    5: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 20, 600, 'normal', 0.00, 1.55),
    6: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 24, 600, 'normal', 0.00, 1.40),
    7: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 30, 600, 'normal', -0.02, 1.32),
    8: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 36, 600, 'normal', -0.03, 1.20),
    9: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 48, 600, 'normal', -0.04, 1.15),
    10: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 60, 600, 'normal', -0.06, 1.08),
    11: generateTypeSizing(HEADING_FONT_FAMILY, HEADING_FONT_FEATURE_SETTINGS, 72, 600, 'normal', -0.06, 1.05),
};
const MONO_FONT_FAMILY: string = `'Consolas', 'Menlo', 'Monaco', 'Roboto Mono', 'Courier New', monospace`;
const MONO_FONT_FEATURE_SETTINGS: string = `"kern", "liga", "clig", "calt", "ss02", "cv11"`;
const MONO_TYPE_SIZING: TypeSizing = {
    1: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 12, 400, 'normal', 0.01, 1.55),
    2: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 14, 400, 'normal', 0.00, 1.47),
    3: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 16, 400, 'normal', 0.00, 1.41),
    4: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 18, 400, 'normal', -0.01, 1.37),
    5: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 20, 400, 'normal', -0.02, 1.33),
    6: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 24, 400, 'normal', -0.03, 1.28),
    7: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 30, 400, 'normal', -0.04, 1.22),
    8: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 36, 400, 'normal', -0.04, 1.18),
    9: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 48, 400, 'normal', -0.04, 1.10),
    10: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 60, 400, 'normal', -0.04, 1.05),
    11: generateTypeSizing(MONO_FONT_FAMILY, MONO_FONT_FEATURE_SETTINGS, 72, 400, 'normal', -0.05, 1),
};
const ICON_SIZING: IconSizing = {
    1: generateTypeSizing('inherit', 'normal', 12, 400, 'normal'),
    2: generateTypeSizing('inherit', 'normal', 16, 400, 'normal'),
    3: generateTypeSizing('inherit', 'normal', 24, 400, 'normal'),
    4: generateTypeSizing('inherit', 'normal', 32, 400, 'normal'),
};

function typeSizing(size: TypeSizes, font: FontTypes = 'body'): TypeSize {
    switch (font) {
        case 'heading':
            return HEADING_TYPE_SIZING[size];
        case 'mono':
            return MONO_TYPE_SIZING[size];
        case 'body':
        default:
            return BODY_TYPE_SIZING[size];
    }
}

function iconSizing(size: IconSizes): TypeSize {
    return ICON_SIZING[size];
}

function pxToRem(px: number): string {
    return `${((px / HTML_FONT_SIZE))}rem`;
}

const APP_TYPOGRAPHY: Typography = {
    sizing: typeSizing,
    iconSizing,
    pxToRem,
    fontFamily: BODY_FONT_FAMILY,
    fontFeatureSettings: {
        body: BODY_FONT_FEATURE_SETTINGS,
        heading: HEADING_FONT_FEATURE_SETTINGS,
        mono: MONO_FONT_FEATURE_SETTINGS
    },
    fontFamilyHeadings: HEADING_FONT_FAMILY,
    fontFamilyMono: MONO_FONT_FAMILY,
    fontSize: FONT_SIZE,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 700,
    // All of these styles do not use `color`. Bad typing on MUI's end
    h1: { ...typeSizing(8, 'heading'), color: 'inherit' },
    h2: { ...typeSizing(7, 'heading'), color: 'inherit' },
    h3: { ...typeSizing(6, 'heading'), color: 'inherit' },
    h4: { ...typeSizing(5, 'heading'), color: 'inherit' },
    h5: { ...typeSizing(4, 'heading'), color: 'inherit' },
    h6: { ...typeSizing(3, 'heading'), color: 'inherit' },
    subtitle1: { ...typeSizing(4, 'heading'), color: 'inherit' },
    subtitle2: { ...typeSizing(3, 'heading'), color: 'inherit' },
    body1: { ...typeSizing(4), color: 'inherit' },
    body1Mono: { ...typeSizing(4, 'mono'), color: 'inherit' },
    body2: { ...typeSizing(3), color: 'inherit' },
    body2Mono: { ...typeSizing(3, 'mono'), color: 'inherit' },
    caption: { ...typeSizing(1), color: 'inherit' },
    captionMono: { ...typeSizing(1, 'mono'), color: 'inherit' },
    button: { ...typeSizing(2), color: 'inherit' },
    buttonMono: { ...typeSizing(2, 'mono'), color: 'inherit' },
    overline: { ...typeSizing(1), color: 'inherit' },
    overlineMono: { ...typeSizing(1, 'mono'), color: 'inherit' },
    blockquote: { ...typeSizing(5), color: 'inherit' }
};

export const TYPOGRAPHY: Typography = {
    // MUI typings are missing 'htmlFontSize' for the `Typography` type.
    // The palette is not used internally, but is required...
    // It defaults to 16 so I'm commenting it out for now
    // ...createTypography(LIGHT_PALETTE_THEME, {
    //     htmlFontSize: HTML_FONT_SIZE, sizing: typeSizing, pxToRem,
    // }),
    ...APP_TYPOGRAPHY
};
