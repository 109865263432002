import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { TypographyProps as MuiTypographyProps, TypographyTypeMap as MuiTypographyTypeMap, TypographyClassKey as MuiTypographyClassKey } from '@material-ui/core/Typography';
import { UseStyles } from '../lib/types/mui';
import { Theme, Typography as MuiTypography, makeStyles } from '@material-ui/core';
import { CustomThemeStyle } from '@material-ui/core/styles/createTypography';
import { OverrideProps } from '@material-ui/core/OverridableComponent';

const HEADINGS: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const MUI_VARIANT_MAPPING: Record<NonNullable<TTypographyP['variant']>, MuiTypographyProps['variant']> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'subtitle1',
    subtitle2: 'subtitle2',
    body1: 'body1',
    body1Mono: 'body1',
    body2: 'body2',
    body2Mono: 'body2',
    caption: 'caption',
    captionMono: 'caption',
    button: 'button',
    buttonMono: 'button',
    overline: 'overline',
    overlineMono: 'overline',
    inherit: 'inherit',
    srOnly: 'srOnly'
};
const MUI_COLOR_MAPPING: Record<TAdditionalTypographyColors, MuiTypographyProps['color']> = {
    lighter: 'inherit',
    light: 'inherit',
    dark: 'inherit',
    darker: 'inherit',
    initial: 'initial',
    inherit: 'inherit'
};

type TAdditionalTypographyColors = 'lighter' | 'light' | 'dark' | 'darker' | 'initial' | 'inherit';

type ClassKey =
    'colorDefault' | 'colorLighter' | 'colorLight' | 'colorDark' | 'colorDarker' | 'gutterTop' | 'regular' | 'bold' |
    'body1Mono' | 'body2Mono' | 'captionMono' | 'buttonMono' | 'overlineMono';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    colorDefault: {
        color: theme.palette.custom.text.color.main,
        '& svg': { color: theme.palette.custom.text.icon.main }
    },
    colorLighter: {
        color: theme.palette.custom.text.color.lighter,
        '& svg': { color: theme.palette.custom.text.icon.lighter }
    },
    colorLight: {
        color: theme.palette.custom.text.color.light,
        '& svg': { color: theme.palette.custom.text.icon.light }
    },
    colorDark: {
        color: theme.palette.custom.text.color.dark,
        '& svg': { color: theme.palette.custom.text.icon.dark }
    },
    colorDarker: {
        color: theme.palette.custom.text.color.darker,
        '& svg': { color: theme.palette.custom.text.icon.darker }
    },
    // Styles applied when variant is h1, h2, h3, h4, h5, or h6
    gutterTop: { marginTop: '2rem' },
    regular: { fontWeight: theme.typography.fontWeightRegular },
    bold: { fontWeight: theme.typography.fontWeightBold },
    body1Mono: theme.typography.body1Mono,
    body2Mono: theme.typography.body2Mono,
    captionMono: theme.typography.captionMono,
    buttonMono: theme.typography.buttonMono,
    overlineMono: theme.typography.overlineMono
}));

export interface ITypographyTypeMap<D extends React.ElementType = MuiTypographyTypeMap['defaultComponent']> {
    props: Omit<MuiTypographyTypeMap<{}, D>['props'], 'color' | 'variant' | 'bold'> & {
        color?: TAdditionalTypographyColors;
        gutterTop?: boolean; // Add a gutter to the top of a heading element
        variant?: CustomThemeStyle | MuiTypographyProps['variant'];
        bold?: boolean;
        regular?: boolean;
    };
    defaultComponent: D;
    classKey: MuiTypographyClassKey | ClassKey;
}

export type TTypographyP<C extends React.ElementType = ITypographyTypeMap['defaultComponent']> =
    OverrideProps<ITypographyTypeMap, C>;

export function Typography<C extends React.ElementType>(props: TReactFCP<TTypographyP<C>>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const {
        gutterTop, className: muiClassName, variant = 'body1', color = 'inherit', bold = false, regular = false,
        ...muiProps
    }: TTypographyP<C> = props;
    const className: string = clsx(
        {
            [styles.body1Mono]: variant === 'body1Mono',
            [styles.body2Mono]: variant === 'body2Mono',
            [styles.captionMono]: variant === 'captionMono',
            [styles.buttonMono]: variant === 'buttonMono',
            [styles.overlineMono]: variant === 'overlineMono',
            [styles.colorDefault]: color !== 'inherit' && color !== 'initial',
            [styles.colorLighter]: color === 'lighter',
            [styles.colorLight]: color === 'light',
            [styles.colorDark]: color === 'dark',
            [styles.colorDarker]: color === 'darker',
            [styles.gutterTop]: gutterTop && HEADINGS.includes(variant || ''),
            [styles.regular]: regular,
            [styles.bold]: bold,
        },
        muiClassName
    );
    return (
        <MuiTypography
            variant={MUI_VARIANT_MAPPING[variant]}
            className={className}
            color={MUI_COLOR_MAPPING[color]}
            {...muiProps}
        />
    );
}
